<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      リース審査結果
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>
            <v-divider />
            <LeasePaymentResult :leasePayment="leasePayment" />
            <v-divider />

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.examinationResultDate)">{{
                      isEmptyValue(creator.examinationResultDate) ? '*審査結果日を登録してください' : '*審査結果日'
                    }}</XCheckLabel>
                    <span
                      v-if="creator.examinationResultDate != null"
                      class="ml-4 success--text"
                      v-text="$options.filters.date(creator.examinationResultDate)"
                    ></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="審査結果日">
                          <validation-provider v-slot="{ errors }" name="審査結果日" rules="required">
                            <DateTime label="審査結果日" v-model="examinationResultDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ examinationResultDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            examinationResultDate = null;
                            creator.examinationResultDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.examinationResultDate = examinationResultDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.state != null && creator.state != 1">{{
                      creator.state == null ? '*審査結果を登録してください' : '*審査結果'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.leasePaymentState(creator.state)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="審査結果" rules="required">
                          <v-select
                            v-model="state"
                            :items="[
                              { text: $options.filters.leasePaymentState(1 << 2), value: 1 << 2 },
                              { text: $options.filters.leasePaymentState(1 << 3), value: 1 << 3 },
                            ]"
                            label="*審査結果"
                            :rules="[(v) => !!v || '審査結果は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                            @change="
                              {
                                // FIXME Editにも同じ処理があるため統合させること
                                let message = meta.ngMessage;
                                if (message == null) message = ``;
                                if (state == 1 << 3) {
                                  message += `${$options.filters.date(new Date())}:`;
                                  message += `${$options.filters.leasePaymentCompanyType(leasePayment.leaseCompanyType)}`;
                                  message += ` NG`;
                                }
                                meta.ngMessage = message;
                              }
                            "
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="NG理由" rules="max:1000">
                          <v-textarea
                            v-if="state == 1 << 3"
                            outlined
                            v-model="meta.ngMessage"
                            label="NG理由"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            state = null;
                            meta.ngMessage = null;
                            creator.state = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.state = state;
                            creator.meta.ngMessage = meta.ngMessage;
                            expansions += creator.state == 1 << 2 ? 1 : 3;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel :disabled="creator.state != 1 << 2">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.rate != null">{{
                      creator.rate == null ? '*料率を登録してください' : '*料率'
                    }}</XCheckLabel>
                    <span v-if="creator.rate != null" class="ml-4 success--text" v-text="`${creator.rate}`"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="料率" rules="required">
                          <v-combobox
                            v-model.number="rate"
                            :items="[1.83, 1.82]"
                            label="料率"
                            :rules="[(v) => !!v || '料率 は必須です']"
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                            required
                            dense
                          ></v-combobox>
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            rate = null;
                            creator.rate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.rate = rate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel :disabled="creator.state != 1 << 2">
                <v-expansion-panel-header>
                  <div>
                    残債
                    <span v-if="creator.residualBonds != null" class="ml-4 success--text">{{ creator.residualBonds | priceJP }}</span>
                  </div>
                  <div>
                    他社買取
                    <span v-if="creator.underwritingBonds != null" class="ml-4 success--text">{{
                      creator.underwritingBonds | priceJP
                    }}</span>
                  </div>
                  <div>
                    サービス品
                    <span v-if="creator.complimentaryBonds != null" class="ml-4 success--text">{{
                      creator.complimentaryBonds | priceJP
                    }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="残債" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="residualBonds"
                            label="残債"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="他社買取" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="underwritingBonds"
                            label="他社買取"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="サービス品" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="complimentaryBonds"
                            label="サービス品"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            residualBonds = null;
                            underwritingBonds = null;
                            complimentaryBonds = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span>備考: </span>
                    <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ leasePaymentCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from '@/components/demos/Result';
import LeasePaymentResult from './Result';
export default {
  components: {
    ApoResult,
    DemoResult,
    LeasePaymentResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    leasePaymentCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit && this.creator.state != 1,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    facilities() {
      return [];
    },
    canCommit() {
      //
      if (this.creator.state == null) return false;
      //
      if (this.creator.state == 1 << 2) {
        if (this.creator.rate == null) return false;
      }

      return true;
    },
  },
  data: () => ({
    //
    initializing: false,

    apo: null,
    demo: null,
    //
    expansions: null, // multipleではいので単体
    //
    examinationResultDate: new Date(),
    state: null,
    rate: null,
    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,
    memo: null,
    meta: {
      ngMessage: null,
    },

    creator: {
      loading: false,

      examinationResultDate: null,
      state: null,
      rate: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,
      memo: null,
      meta: {
        ngMessage: null,
      },
    },
  }),
  methods: {
    init() {
      //

      //
      this.expansions = null;
      this.examinationResultDate = new Date();

      this.leasePayment = null;

      //
      this.memo = null;
      //
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //
      this.get('leasePayment', { leasePaymentCode: this.leasePaymentCode })
        .then((success) => {
          //
          let data = success.data;

          this.leasePayment = data;

          this.demo = data.demo;
          if (data.demo != null) {
            this.apo = data.demo.apo;

            this.residualBonds = data.demo.residualBonds;
            this.underwritingBonds = data.demo.underwritingBonds;
            this.complimentaryBonds = data.demo.complimentaryBonds;
            this.creator.residualBonds = data.demo.residualBonds;
            this.creator.underwritingBonds = data.demo.underwritingBonds;
            this.creator.complimentaryBonds = data.demo.complimentaryBonds;
          }
          // //

          this.examinationDate = data.examinationDate;
          this.examinationResultDate = data.examinationResultDate;
          this.state = data.state;
          this.rate = data.rate;
          this.memo = data.memo;
          this.meta = data.meta;
          // //

          this.creator.examinationDate = data.examinationDate;
          this.creator.examinationResultDate = data.examinationResultDate;
          this.creator.state = data.state;
          this.creator.rate = data.rate;
          this.creator.memo = data.memo;
          this.creator.meta = data.meta;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
      //
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = this.creator;
      request.leasePaymentCode = this.leasePaymentCode;
      request.examinationResultDate = this.convertDate(this.creator.examinationResultDate);
      //

      // nullable
      request.rate = this.convertNumber(request.rate);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      this.put('leasePayment/result', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.leasePaymentCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>