<template>
  <v-card flat>
    <template v-if="disp == 'list1'">
      <List1 class="mb-4" v-for="(data, index) in [
        {
          title: '審査中',
          type: 1,
          items: getDemoDatas(DemoResultTypes.Examination),
          totalAmount: getDemoDatas(DemoResultTypes.Examination).reduce((sum, element) => (sum += element.resultAmount2), 0),
        },
        {
          title: '未導入物件',
          type: 1 << 1,
          items: getDemoDatas(DemoResultTypes.UnHPCoverageScheduled),
          totalAmount: getDemoDatas(DemoResultTypes.UnHPCoverageScheduled).reduce((sum, element) => (sum += element.resultAmount2), 0),
        },
        {
          title: '導入決定物件',
          type: 1 << 2,
          items: getHPCoverageDatas(HPCoverageStates.Scheduled),
          totalAmount: getHPCoverageDatas(HPCoverageStates.Scheduled).reduce(
            (sum, element) => (sum += element.demo != null ? element.demo.resultAmount2 : 0),
            0
          ),
        },
        {
          title: 'リース開始確認待ち',
          type: 1 << 3,
          items: getLeasePaymentDatas(null, LeasePaymentStartStates.NotStart),
          totalAmount: getLeasePaymentDatas(null, LeasePaymentStartStates.NotStart).reduce(
            (sum, element) => (sum += element.demo != null ? element.demo.resultAmount2 : 0),
            0
          ),
        },
      ]" :title="data.title" :type="data.type" :items="data.items" :totalAmount="data.totalAmount" :key="`list1_${index}`"></List1>
    </template>
    <template v-else>
      <v-card-title>
        <h5>{{ title }}</h5>
        <v-spacer />
        <v-btn text color="primary" @click="dataFilter.show = !dataFilter.show"> 詳細検索 <v-icon v-text="dataFilter.show ? 'mdi-chevron-down' : 'mdi-chevron-up'" right />
        </v-btn>
        <v-switch v-model="flow.show" label="フロー表示" dense />
        <span class="ml-2">
          <v-btn outlined @click="onCSVDownload">
            <v-icon v-text="`mdi-download-outline`" left /> CSV </v-btn>
          <span class="text-caption">&nbsp;</span>
        </span>
      </v-card-title>
      <v-expand-transition mode="out-in">
        <v-card v-show="dataFilter.show" outlined>
          <DataFilter :show="dataFilter.show" :disps="filterDisp" @commit="onDataFilterCommit" />
        </v-card>
      </v-expand-transition>
        <v-overlay
          v-model="progress.loading"
          contained
          class="align-center justify-center"
        >
          <v-sheet width="90vw" color="transparent">
            <p class="text-white">
              <v-progress-linear indeterminate/>
              <span>{{progress.message}}</span>
            </p>
          </v-sheet>
        </v-overlay>
      <XSheet v-if="apoData.state != null" :loading="apoData.loading">
        <template v-slot="{ loaded }">

          <v-data-table v-if="loaded" :headers="[
            { text: 'コード', value: 'code' },
            { text: 'アポ日', value: 'date' },
            { text: '宿名', value: 'facility.name' },
            { text: 'デモ対象', value: 'demoTarget' },

            { text: 'アポ種別', value: 'apoType' },
            { text: 'アポ担当', value: 'apointer' },
            { text: '支店', value: 'branch' },
            { text: 'デモ日程', value: 'demoSchedule' },
            { text: 'デモ日程確認済み', value: 'isConfirmDemoScheduleDate' },
            { text: 'キャンセル済み', value: 'isCancelled' },
            { text: '操作', value: 'action' },
          ]" :items="getApoDatas(apoData.state)" class="elevation-1" :items-per-page="-1" dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ item.date | date }}
            </template>
            <template v-slot:item.apoType="{ item }">
              {{ item.apoType | apoType }}
            </template>
            <template v-slot:item.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.demoTarget="{ item }">
              {{ item.demoTarget }}
            </template>
            <template v-slot:item.apointer="{ item }">
              <span v-if="item.apointer != null"> {{ item.apointer.name }}</span>
            </template>
            <template v-slot:item.team="{ item }">
              {{ item.team != null ? item.team.name : '' }}
            </template>
            <template v-slot:item.branch="{ item }">
              {{ item.branch.name }}
            </template>
            <template v-slot:item.demoSchedule="{ item }">
              {{ item.demoSchedule | dateTime }}
            </template>
            <template v-slot:item.isConfirmDemoScheduleDate="{ item }">
              {{ item.isConfirmDemoScheduleDate ? '〇' : '×' }}
            </template>
            <template v-slot:item.isCancelled="{ item }">
              {{ item.isCancelled ? '〇' : '×' }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="apoData.state == ApoStates.Default" text color="primary" @click="
                {
                  apoDemoSchedule.apoCode = item.code;
                  apoDemoSchedule.show = true;
                }
              ">デモ日程確認登録</v-btn>
              <v-btn v-else-if="apoData.state == ApoStates.DemoScheduleConfirmed" text color="primary" @click="
                {
                  demoCreator.apoCode = item.code;
                  demoCreator.show = true;
                }
              ">デモ結果報告</v-btn>
              <v-btn v-else text color="primary" @click="
                {
                  apoEditor.apoCode = item.code;
                  apoEditor.show = true;
                }
              ">アポ情報編集</v-btn>
            </template>
          </v-data-table>
        </template>
      </XSheet>
      <XSheet v-if="demoData.state != null" :loading="demoData.loading">
        <template v-slot="{ loaded }">
          <v-data-table v-if="loaded" :headers="[
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'facility.name' },
            { text: 'デモ日', value: 'date' },
            { text: 'システム', value: 'systemTypes' },
            { text: 'デモ結果', value: 'resultType' },
            { text: '報告区分', value: 'reportType' },
            { text: '売上区分', value: 'saleType' },
            { text: 'アポ担当', value: 'apointer' },
            { text: 'デモ担当', value: 'demointer' },
            { text: '支店', value: 'branch' },
            { text: '売上', value: 'sales' },
            { text: '初期費用', value: 'initialCost' },
            { text: '操作', value: 'action' },
          ]" :items="getDemoDatas(demoData.state)" class="elevation-1" :items-per-page="-1" dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.date="{ item }">
              {{ item.date | date }}
            </template>
            <template v-slot:item.systemTypes="{ item }">
              {{ item.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode }}
            </template>
            <template v-slot:item.resultType="{ item }">
              <span>{{ item.resultType | demoResultType }}</span>
              <v-chip v-if="item.cancelDate != null" class="ml-2" color="warning" x-small>
                <v-icon v-text="'mdi-close-octagon-outline'" />{{ item.cancelDate | date }}
              </v-chip>
            </template>
            <template v-slot:item.reportType="{ item }">
              {{ item.reportType | demoReportType }}
            </template>
            <template v-slot:item.saleType="{ item }">
              {{ item.saleType | demoSaleType }}
            </template>
            <template v-slot:item.apointer="{ item }">
              <span v-if="item.apo != null && item.apo.apointer != null">{{ item.apo.apointer.name }}</span>
              <span v-else-if="item.apointer != null">{{ item.apointer.name }}</span>
            </template>
            <template v-slot:item.demointer="{ item }">
              {{ item.demointer.name }}
            </template>
            <template v-slot:item.branch="{ item }"> {{ item.branch.name }} </template>
            <template v-slot:item.sales="{ item }"> {{ item.sales | priceJP }} </template>
            <template v-slot:item.initialCost="{ item }"> {{ item.initialCost | priceJP }} </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="demoData.state == DemoResultTypes.PreExamination" text color="primary" @click="
                {
                  demoSubmitPreExamination.demoCode = item.code;
                  demoSubmitPreExamination.show = true;
                }
              ">事前審査書提出</v-btn>
              <template v-else-if="demoData.state == DemoResultTypes.Examination">
                <v-btn v-if="!item.hasLeasePayment" text color="primary" @click="
                  {
                    leasePaymentCreator.demoCode = item.code;
                    leasePaymentCreator.show = true;
                  }
                ">審査結果登録 </v-btn>
                <v-btn v-else-if="!item.hasLeasePaymentReport" text color="primary" @click="
                  {
                    leasePaymentEditor.leasePaymentCode = item.leasePayment.code;
                    leasePaymentEditor.show = true;
                    leasePaymentEditor.viewStartState = null; //リース審査編集として表示させる
                  }
                ">審査結果登録* </v-btn>
              </template>
              <v-btn v-else-if="demoData.state == DemoResultTypes.UnHPCoverageScheduled" text color="primary" @click="
                {
                  hpCoverageCreator.demoCode = item.code;
                  hpCoverageCreator.show = true;
                }
              ">導入日程</v-btn>
              <v-btn v-else-if="demoData.state == DemoResultTypes.NoInvoiced" text color="primary" @click="
                {
                  saleCreator.demoCode = item.code;
                  saleCreator.show = true;
                }
              ">請求書発行</v-btn>
              <v-btn v-else-if="demoData.state == DemoResultTypes.NoDeposited" text color="primary" @click="
                {
                  saleEditor.demoCode = item.code;
                  saleEditor.show = true;
                }
              ">入金処理</v-btn>
              <v-btn v-else-if="demoData.state == DemoResultTypes.Deposited" text color="primary" @click="
                {
                  saleEditor.demoCode = item.code;
                  saleEditor.show = true;
                }
              ">入金処理編集</v-btn>
              <template v-else-if="demoData.state != DemoResultTypes.Default">
                <v-btn text color="primary" @click="
                  {
                    demoEditor.demoCode = item.code;
                    demoEditor.show = true;
                  }
                ">デモ編集</v-btn>
              </template>
            </template>
            <template v-slot:footer>
              <v-container fluid>
                <v-list-item>
                  <v-spacer />
                  <v-list-item-icon>
                    <span class="text-caption">売上合計:</span>
                    <span class="ml-4 font-weight-bold">{{ getDemoDatas(demoData.state).reduce((sum, element) => (sum += element.resultAmount2), 0) | priceJP
                    }}</span>
                  </v-list-item-icon>
                </v-list-item>
              </v-container>
            </template>
          </v-data-table>
        </template>
      </XSheet>
      <XSheet v-if="hpCoverageData.state != null" :loading="hpCoverageData.loading">
        <template v-slot="{ loaded }">
          <v-data-table v-if="loaded" :headers="HPCoverageTitle" :items="getHPCoverageDatas(hpCoverageData.state)" class="elevation-1" :items-per-page="-1" dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.demo.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.demo != null" text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.demo.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.demo.facility.name }}({{ item.demo.facility.pref != null ? item.demo.facility.pref.name : '' }} {{ item.demo.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.demo.facility.name }}({{ item.demo.facility.pref != null ? item.demo.facility.pref.name : '' }} {{ item.demo.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.demo.date="{ item }">
              <span>{{ item.demo.date | date }}</span>
            </template>
            <template v-slot:item.scheduleDate="{ item }">
              <span>{{ item.scheduleDate | date }}</span>
            </template>
            <template v-slot:item.date="{ item }">
              <span>{{ item.date | date }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="hpCoverageData.state == HPCoverageStates.Scheduled" text color="primary" @click="
                {
                  hpCoverageScheduleEditor.hpCoverageCode = item.code;
                  hpCoverageScheduleEditor.show = true;
                }
              ">導入取材日程変更 </v-btn>
              <v-btn v-if="hpCoverageData.state == HPCoverageStates.Scheduled" text color="primary" @click="
                {
                  hpCoverageEditor.hpCoverageCode = item.code;
                  hpCoverageEditor.show = true;
                }
              ">導入取材報告 </v-btn>
              <v-btn v-else-if="hpCoverageData.state == HPCoverageStates.Complete" text color="primary" @click="
                {
                  hpCoverageEditor.hpCoverageCode = item.code;
                  hpCoverageEditor.show = true;
                }
              ">導入取材修正 </v-btn>
            </template>
            <template v-slot:footer>
              <v-container fluid>
                <v-list-item>
                  <v-spacer />
                  <v-list-item-icon>
                    <span class="text-caption">売上合計:</span>
                    <span class="ml-4 font-weight-bold">{{ getHPCoverageDatas(hpCoverageData.state).reduce(
                      (sum, element) => (sum += element.demo != null ? element.demo.resultAmount2 : 0),
                      0
                    ) | priceJP
                    }}</span>
                  </v-list-item-icon>
                </v-list-item>
              </v-container>
            </template>
          </v-data-table>
        </template>
      </XSheet>
      <XSheet v-if="leasePaymentData.state != null" :loading="leasePaymentData.loading">
        <template v-slot="{ loaded }">
          <v-data-table v-if="loaded" :headers="[
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'facility.name' },
            { text: 'デモ日', value: 'demo.date' },
            { text: 'システム', value: 'demo.systemTypes' },
            { text: 'アポ担当', value: 'demo.apointer' },
            { text: 'デモ担当', value: 'demo.demointer' },
            { text: '支店', value: 'demo.branch' },
            { text: '売上', value: 'demo.sales' },
            { text: '初期費用', value: 'demo.initialCost' },

            { text: 'リース会社', value: 'leaseCompanyType' },
            { text: '審査提出日', value: 'examinationDate' },
            { text: '審査結果', value: 'state' },
            { text: '料率', value: 'rate' },

            { text: '操作', value: 'action' },
          ]" :items="getLeasePaymentDatas(leasePaymentData.state, leasePaymentData.startState)" class="elevation-1" :items-per-page="-1" dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.demo.date="{ item }">
              <span v-if="item.demo != null">{{ item.demo.date | date }}</span>
            </template>
            <template v-slot:item.demo.systemTypes="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode }}
              </span>
            </template>
            <template v-slot:item.demo.apointer="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.apointer != null ? item.demo.apointer.name : '' }}
              </span>
            </template>
            <template v-slot:item.demo.demointer="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.demointer.name }}
              </span>
            </template>
            <template v-slot:item.demo.branch="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.branch.name }}
              </span></template>
            <template v-slot:item.demo.sales="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.sales | priceJP }}
              </span>
            </template>
            <template v-slot:item.demo.initialCost="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.initialCost | priceJP }}
              </span>
            </template>
            <template v-slot:item.leaseCompanyType="{ item }">
              {{ item.leaseCompanyType | leasePaymentCompanyTypeShort }}
            </template>
            <template v-slot:item.examinationDate="{ item }"> {{ item.examinationDate | date }} </template>
            <template v-slot:item.state="{ item }"> {{ item.state | leasePaymentState }} </template>
            <template v-slot:item.rate="{ item }"> {{ item.rate }} </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="leasePaymentData.state == LeasePaymentStates.Examination" text color="primary" @click="
                {
                  leasePaymentReport.leasePaymentCode = item.code;
                  leasePaymentReport.show = true;
                }
              ">リース審査登録</v-btn>
              <v-btn v-else text color="primary" @click="
                {
                  leasePaymentEditor.leasePaymentCode = item.code;
                  leasePaymentEditor.show = true;
                }
              ">
                <template>リース審査編集</template>
              </v-btn>
            </template>
            <template v-slot:footer>
              <v-container fluid>
                <v-list-item>
                  <v-spacer />
                  <v-list-item-icon>
                    <span class="text-caption">売上合計:</span>
                    <span class="ml-4 font-weight-bold">{{ getLeasePaymentDatas(leasePaymentData.state, leasePaymentData.startState).reduce(
                      (sum, element) => (sum += element.demo != null ? element.demo.resultAmount2 : 0),
                      0
                    ) | priceJP
                    }}</span>
                  </v-list-item-icon>
                </v-list-item>
              </v-container>
            </template>
          </v-data-table>
        </template>
      </XSheet>
      <XSheet v-if="leasePaymentData.startState != null" :loading="leasePaymentData.loading">
        <template v-slot="{ loaded }">
          <v-data-table v-if="loaded" :headers="[
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'facility.name' },
            { text: '見込み売上', value: 'demo.sales' },
            { text: '導入取材', value: 'demo.hpCoverage' },
            { text: 'リース会社', value: 'leaseCompanyType' },
            { text: '料率', value: 'rate' },
            { text: 'リース開始確認日', value: 'startDate' },
            { text: '審査結果', value: 'state' },
            { text: '開始状況', value: 'startState' },
            { text: '入金予定日', value: 'completeScheduleDate' },

            { text: '操作', value: 'action' },
          ]" :items="getLeasePaymentDatas(leasePaymentData.state, leasePaymentData.startState)" class="elevation-1" :items-per-page="-1" dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.demo.sales="{ item }">
              {{ item.demo.sales | priceJP }}
            </template>
            <template v-slot:item.demo.hpCoverage="{ item }">
              <span v-if="item.demo.hpCoverage != null">{{ item.demo.hpCoverage.date | date }}</span>
            </template>
            <template v-slot:item.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.demo.date="{ item }">
              <span v-if="item.demo != null">{{ item.demo.date | date }}</span>
            </template>
            <template v-slot:item.demo.systemTypes="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) }}
              </span>
            </template>
            <template v-slot:item.demo.apointer="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.apointer != null ? item.demo.apointer.name : '' }}
              </span>
            </template>
            <template v-slot:item.demo.demointer="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.demointer.name }}
              </span>
            </template>
            <template v-slot:item.demo.branch="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.branch.name }}
              </span></template>
            <template v-slot:item.demo.sales="{ item }">
              <span v-if="item.demo != null">
                {{ item.demo.sales | priceJP }}
              </span>
            </template>
            <template v-slot:item.leaseCompanyType="{ item }">
              {{ item.leaseCompanyType | leasePaymentCompanyTypeShort }}
            </template>
            <template v-slot:item.completeScheduleDate="{ item }"> {{ item.completeScheduleDate | date }} </template>
            <template v-slot:item.startDate="{ item }"> {{ item.startDate | date }} </template>
            <template v-slot:item.state="{ item }"> {{ item.state | leasePaymentState }} </template>
            <template v-slot:item.startState="{ item }"> {{ item.startState | leasePaymentStartState }} </template>
            <template v-slot:item.rate="{ item }"> {{ item.rate }} </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="leasePaymentData.state == LeasePaymentStates.Examination" text color="primary" @click="
                {
                  leasePaymentReport.leasePaymentCode = item.code;
                  leasePaymentReport.show = true;
                }
              ">リース審査登録</v-btn>
              <v-btn v-else text color="primary" @click="
                {
                  leasePaymentEditor.leasePaymentCode = item.code;
                  leasePaymentEditor.show = true;
                  leasePaymentEditor.viewStartState = leasePaymentData.startState;
                }
              ">
                <template v-if="(leasePaymentData.startState & LeasePaymentStartStates.UnCollected) != 0">リース用紙提出</template>
                <template v-else-if="(leasePaymentData.startState & LeasePaymentStartStates.NotStart) != 0">リース開始登録</template>
                <template v-else-if="(leasePaymentData.startState & LeasePaymentStartStates.NotComplete) != 0">入金登録</template>
                <template v-else>リース審査編集 </template>
              </v-btn>
            </template>
            <template v-slot:footer>
              <v-container fluid>
                <v-list-item>
                  <v-spacer />
                  <v-list-item-icon>
                    <span class="text-caption">売上合計:</span>
                    <span class="ml-4 font-weight-bold">{{ getLeasePaymentDatas(leasePaymentData.state, leasePaymentData.startState).reduce(
                      (sum, element) => (sum += element.demo != null ? element.demo.resultAmount2 : 0),
                      0
                    ) | priceJP
                    }}</span>
                  </v-list-item-icon>
                </v-list-item>
              </v-container>
            </template>
          </v-data-table>
        </template>
      </XSheet>
      <XSheet v-if="contractDocumentData.state != null" :loading="contractDocumentData.loading">
        <template v-slot="{ loaded }">
          <v-data-table v-if="loaded" :headers="[
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'facility.name' },

            { text: 'デモ日', value: 'date' },
            { text: '売上区分', value: 'saleType' },
            { text: 'あすなろシステム契約書', value: 'system' },
            { text: 'リース契約書', value: 'lease' },
            { text: '納品確認書', value: 'delivery' },
            { text: 'サポート規約', value: 'support' },
            { text: 'すけさん契約申込書', value: 'sukesan' },
            { text: 'ワイドネット', value: 'wideNet' },

            { text: '操作', value: 'action' },
          ]" :items="getContractDocumentDatas(contractDocumentData.state)" class="elevation-1" :items-per-page="-1" multi-sort dense>
            <template v-slot:item.code="{ item }">
              {{ item.code | lessStr(5, '..') }}
            </template>
            <template v-slot:item.facility.name="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="
                    {
                      facilityData.facilityCode = item.facility.code;
                      facilityData.show = true;
                    }
                  ">
                    <span class="d-inline-block text-truncate" style="max-width: 250px">
                      {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </span>
                  </v-btn>
                </template>
                {{ item.facility.name }}({{ item.facility.pref != null ? item.facility.pref.name : '' }} {{ item.facility.areaName }}) </v-tooltip>
            </template>
            <template v-slot:item.date="{ item }">
              <span>{{ item.date | date }}</span>
            </template>
            <template v-slot:item.saleType="{ item }">
              {{ item.saleType | demoSaleType }}
            </template>
            <template v-slot:item.system="{ item }">
              <DocState :demo="item" :type="1" />
            </template>
            <template v-slot:item.lease="{ item }">
              <DocState :demo="item" :type="1 << 1" />
            </template>
            <template v-slot:item.delivery="{ item }">
              <DocState :demo="item" :type="1 << 2" />
            </template>
            <template v-slot:item.support="{ item }">
              <DocState :demo="item" :type="1 << 3" />
            </template>
            <template v-slot:item.sukesan="{ item }">
              <DocState :demo="item" :type="1 << 4" />
            </template>
            <template v-slot:item.wideNet="{ item }">
              <DocState :demo="item" :type="1 << 5" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text color="primary" @click="
                {
                  demoContractDocumentEditor.demoCode = item.code;
                  demoContractDocumentEditor.show = true;
                }
              ">書類提出状況編集</v-btn>
            </template>
          </v-data-table>
         
          
         
        </template>
      </XSheet>
      <v-navigation-drawer v-model="flow.show" right app width="300">
        <v-sheet color="grey lighten-4" class="pa-3" align="center">
          <div>フロー</div>
        </v-sheet>
        <v-sheet align="center">
          <v-timeline dense clipped v-if="flow.type == 1">
            <v-timeline-item fill-dot class="white--text" color="orange" large>
              <template v-slot:icon>
                <span>アポ</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="orange--text font-weight-bold">- アポイント -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = ApoStates.Default;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>デモ日程確認待ち({{ getApoDatas(ApoStates.Default).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = ApoStates.Cancelled;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>キャンセル({{ getApoDatas(ApoStates.Cancelled).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = ApoStates.Missed;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>入力ミス({{ getApoDatas(ApoStates.Missed).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="green" large>
              <template v-slot:icon>
                <span>デモ</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="green--text font-weight-bold">- デモ -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = ApoStates.DemoScheduleConfirmed;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>デモ結果報告待ち({{ getApoDatas(ApoStates.DemoScheduleConfirmed).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.OK;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>受注({{ getDemoDatas(DemoResultTypes.OK).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.NG;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>デモ結果NG({{ getDemoDatas(DemoResultTypes.NG).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.Wait;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>返事待ち({{ getDemoDatas(DemoResultTypes.Wait).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.ReDemo;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>再デモ({{ getDemoDatas(DemoResultTypes.ReDemo).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.Cancelled;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>キャンセル({{ getDemoDatas(DemoResultTypes.Cancelled).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.Missed;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>入力ミス({{ getDemoDatas(DemoResultTypes.Missed).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="cyan" large>
              <template v-slot:icon>
                <span>リース</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="cyan--text font-weight-bold">- リース -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.Examination;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>リース審査中({{ getDemoDatas(DemoResultTypes.Examination).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = LeasePaymentStates.OK;

                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>リースOK({{ getLeasePaymentDatas(LeasePaymentStates.OK, null).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = LeasePaymentStates.NG;

                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>リースNG({{ getLeasePaymentDatas(LeasePaymentStates.NG, null).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = LeasePaymentStates.AllNG;

                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>リース全NG({{ getLeasePaymentDatas(LeasePaymentStates.AllNG, null).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="purple" large>
              <template v-slot:icon>
                <span>導入</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="purple--text font-weight-bold">- 導入取材 -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.UnHPCoverageScheduled;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>導入日未定({{ getDemoDatas(DemoResultTypes.UnHPCoverageScheduled).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = HPCoverageStates.Scheduled;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>導入日決定物件({{ getHPCoverageDatas(HPCoverageStates.Scheduled).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = HPCoverageStates.Complete;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>導入・取材完了({{ getHPCoverageDatas(HPCoverageStates.Complete).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="pink" large>
              <template v-slot:icon>
                <span>リ金</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="pink--text font-weight-bold">- リース入金 -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = LeasePaymentStartStates.NotStart;
                  contractDocumentData.state = null;
                }
              ">
                <span>リース開始待ち({{ getLeasePaymentDatas(null, LeasePaymentStartStates.NotStart).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = LeasePaymentStartStates.NotComplete;
                  contractDocumentData.state = null;
                }
              ">
                <span>入金待ち({{ getLeasePaymentDatas(null, LeasePaymentStartStates.NotComplete).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = LeasePaymentStartStates.CompleteOK | LeasePaymentStartStates.Rental;
                  contractDocumentData.state = null;
                }
              ">
                <span>入金済({{ getLeasePaymentDatas(null, LeasePaymentStartStates.CompleteOK | LeasePaymentStartStates.Rental).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = LeasePaymentStartStates.CompleteNG;
                  contractDocumentData.state = null;
                }
              ">
                <span>入金NG({{ getLeasePaymentDatas(null, LeasePaymentStartStates.CompleteNG).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot color="yellow darken-3" large>
              <template v-slot:icon>
                <span>現レ</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="yellow--text darken-3 font-weight-bold">- 現金・レンタル -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.NoInvoiced;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>請求書未発行({{ getDemoDatas(DemoResultTypes.NoInvoiced).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.NoDeposited;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>未入金({{ getDemoDatas(DemoResultTypes.NoDeposited).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = DemoResultTypes.Deposited;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = null;
                }
              ">
                <span>入金済み({{ getDemoDatas(DemoResultTypes.Deposited).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="indigo darken-1" large>
              <template v-slot:icon>
                <span>確認</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="indigo--text font-weight-bold">- 未提出書類等 -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = ContractDocumentStates.UnCollected;
                }
              ">
                <span>未提出書類({{ getContractDocumentDatas(ContractDocumentStates.UnCollected).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text @click="
                {
                  apoData.state = null;
                  demoData.state = null;
                  hpCoverageData.state = null;
                  leasePaymentData.state = null;
                  leasePaymentData.startState = null;
                  contractDocumentData.state = ContractDocumentStates.Collected;
                }
              ">
                <span>書類提出完了({{ getContractDocumentDatas(ContractDocumentStates.Collected).length }})</span>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item fill-dot class="white--text" color="light-blue darken-1" large>
              <template v-slot:icon>
                <span>稼働</span>
              </template>
              <v-row class="pt-4">
                <v-col>
                  <span class="light-blue--text font-weight-bold">- 稼働 -</span>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 構成提出待ち(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> ラフ作成中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> ラフ確認中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 上段作成中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 上段確認中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 作成中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 仮アップ中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 納品確認中(-) </v-btn>
            </v-timeline-item>
            <v-timeline-item small>
              <v-btn text> 本アップ完了(-) </v-btn>
            </v-timeline-item>
          </v-timeline>
        </v-sheet>
      </v-navigation-drawer>
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="facilityData.show" inset>
          <FacilityEdit :show="facilityData.show" :observer="observer" :facilityCode="facilityData.facilityCode" @cancel="facilityData.show = false" />
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- デモ日程確認 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="apoDemoSchedule.show" inset>
          <ApoDemoSchedule :show="apoDemoSchedule.show" :observer="observer" :apoCode="apoDemoSchedule.apoCode" @commit="onApoDemoSchedule" @error="apoDemoSchedule.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="apoDemoSchedule.show = false">閉じる</v-btn>
            </template>
          </ApoDemoSchedule>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- アポ内容編集 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="apoEditor.show" inset>
          <ApoEdit :show="apoEditor.show" :observer="observer" :apoCode="apoEditor.apoCode" @commit="onApoEdit" @error="apoEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="apoEditor.show = false">閉じる</v-btn>
            </template>
          </ApoEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- デモ結果報告 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="demoCreator.show" inset>
          <DemoCreate :show="demoCreator.show" :observer="observer" :apoCode="demoCreator.apoCode" @commit="onDemoCreate" @error="demoCreator.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="demoCreator.show = false">閉じる</v-btn>
            </template>
          </DemoCreate>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- デモ内容編集 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="demoEditor.show" inset>
          <DemoEdit :show="demoEditor.show" :observer="observer" :demoCode="demoEditor.demoCode" @commit="onDemoEdit" @error="demoEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="demoEditor.show = false">閉じる</v-btn>
            </template>
          </DemoEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- デモ提出書類編集 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="demoContractDocumentEditor.show" inset>
          <DemoContractDocumentEdit :show="demoContractDocumentEditor.show" :observer="observer" :demoCode="demoContractDocumentEditor.demoCode" @commit="onDemoContractDocumentEdit" @error="demoContractDocumentEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="demoContractDocumentEditor.show = false">閉じる</v-btn>
            </template>
          </DemoContractDocumentEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- 事前審査書提出 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="demoSubmitPreExamination.show" inset>
          <DemoSubmitPreExamination :show="demoSubmitPreExamination.show" :observer="observer" :demoCode="demoSubmitPreExamination.demoCode" @commit="onDemoSubmitPreExamination" @error="demoSubmitPreExamination.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="demoSubmitPreExamination.show = false">閉じる</v-btn>
            </template>
          </DemoSubmitPreExamination>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- 取材日程報告 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="hpCoverageCreator.show" inset>
          <HPCoverageCreate :show="hpCoverageCreator.show" :observer="observer" :demoCode="hpCoverageCreator.demoCode" @commit="onHPCoverageCreate" @error="hpCoverageCreator.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="hpCoverageCreator.show = false">閉じる</v-btn>
            </template>
          </HPCoverageCreate>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- 取材日程変更編集 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="hpCoverageScheduleEditor.show" inset>
          <HPCoverageScheduleEdit :show="hpCoverageScheduleEditor.show" :observer="observer" :hpCoverageCode="hpCoverageScheduleEditor.hpCoverageCode" @commit="onHPCoverageScheduleEdit" @commitClear="onHPCoverageScheduleEditClear" @error="hpCoverageScheduleEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="hpCoverageScheduleEditor.show = false">閉じる</v-btn>
            </template>
          </HPCoverageScheduleEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- 導入取材報告 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="hpCoverageEditor.show" inset>
          <HPCoverageEdit :show="hpCoverageEditor.show" :observer="observer" :hpCoverageCode="hpCoverageEditor.hpCoverageCode" @commit="onHPCoverageEdit" @error="hpCoverageEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="hpCoverageEditor.show = false">閉じる</v-btn>
            </template>
          </HPCoverageEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- リース審査結果登録 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="leasePaymentCreator.show" inset>
          <LeasePaymentCreate :show="leasePaymentCreator.show" :observer="observer" :demoCode="leasePaymentCreator.demoCode" @commit="onLeasePaymentCreate" @error="leasePaymentCreator.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="leasePaymentCreator.show = false">閉じる</v-btn>
            </template>
          </LeasePaymentCreate>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- リース審査結果報告 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="leasePaymentReport.show" inset>
          <LeasePaymentReport :show="leasePaymentReport.show" :observer="observer" :leasePaymentCode="leasePaymentReport.leasePaymentCode" @commit="onLeasePaymentReport" @error="leasePaymentReport.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="leasePaymentReport.show = false">閉じる</v-btn>
            </template>
          </LeasePaymentReport>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- リース内容編集 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="leasePaymentEditor.show" inset>
          <LeasePaymentEdit :show="leasePaymentEditor.show" :observer="observer" :leasePaymentCode="leasePaymentEditor.leasePaymentCode" :viewStartState="leasePaymentEditor.viewStartState" @commit="onLeasePaymentEdit" @error="leasePaymentEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="leasePaymentEditor.show = false">閉じる</v-btn>
            </template>
          </LeasePaymentEdit>
        </v-bottom-sheet>
      </ValidationObserver>
      <!-- 請求書発行 -->
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="saleCreator.show" inset>
          <SaleCreate :show="saleCreator.show" :observer="observer" :demoCode="saleCreator.demoCode" @commit="onSaleCreate" @error="saleCreator.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="saleCreator.show = false">閉じる</v-btn>
            </template>
          </SaleCreate>
        </v-bottom-sheet>
      </ValidationObserver>
      <ValidationObserver v-slot="observer">
        <v-bottom-sheet persistent scrollable v-model="saleEditor.show" inset>
          <SaleEdit :show="saleEditor.show" :observer="observer" :demoCode="saleEditor.demoCode" @commit="onSaleEdit" @error="saleEditor.show = false">
            <template v-slot:close>
              <v-btn text color="warning" @click="saleEditor.show = false">閉じる</v-btn>
            </template>
          </SaleEdit>
        </v-bottom-sheet>
      </ValidationObserver>
    </template>
  </v-card>
</template>


<script>
import DataFilter from '@/components/filters/DataFilter';

import List1 from './lists/List1';

import FacilityEdit from '@/components/facilities/Edit';

import ApoDemoSchedule from '@/components/apos/DemoSchedule';
import ApoEdit from '@/components/apos/Edit';

import DemoCreate from '@/components/demos/Create';
import DemoEdit from '@/components/demos/Edit';

import DemoContractDocumentEdit from '@/components/demos/contractDocuments/Edit';
import DemoSubmitPreExamination from '@/components/demos/SubmitPreExamination';

import DocState from '@/components/demos/contractDocuments/DocState';

import HPCoverageCreate from '@/components/hpCoverages/Create';
import HPCoverageEdit from '@/components/hpCoverages/Edit';
import HPCoverageScheduleEdit from '@/components/hpCoverages/ScheduleEdit';

import LeasePaymentCreate from '@/components/leasePayments/Create';
import LeasePaymentReport from '@/components/leasePayments/Report';
import LeasePaymentEdit from '@/components/leasePayments/Edit';

import SaleCreate from '@/components/sales/Create';
import SaleEdit from '@/components/sales/Edit';

import { ApoStates } from '@/types';

import { DemoResultTypes, FilterDisps } from '@/types';

import { download } from '@/util/file';

const DemoSaleTypes = {
  Lease: 1, // リース
  Rental: 1 << 1, // レンタル
  Cash: 1 << 2, // 現金
  Margin: 1 << 3, // 手数料
  Stocking: 1 << 4, // 仕入販売
};

const HPCoverageStates = {
  Default: 1, // 未定
  Scheduled: 1 << 1, // 予定済み
  Complete: 1 << 2, // 取材済み
  Cancelled: 1 << 3, // 取材キャンセル
  Retake: 1 << 4, // 再取材
};

const LeasePaymentStates = {
  Default: 1, // リース会社提出待ち
  Examination: 1 << 1, // リース会社提出(審査中)
  OK: 1 << 2, // 結果OK
  NG: 1 << 3, // 結果NG
  AllNG: 1 << 4, // 結果全NG
};

import { LeasePaymentStartStates } from '@/types';

const ContractDocumentStates = {
  UnCollected: 1, // 未回収
  Collected: 1 << 1, // 回収済み
};

export default {
  components: {
    DataFilter,

    FacilityEdit,

    ApoDemoSchedule,
    ApoEdit,

    DemoCreate,
    DemoEdit,

    DemoContractDocumentEdit,
    DemoSubmitPreExamination,

    DocState,

    HPCoverageCreate,
    HPCoverageEdit,
    HPCoverageScheduleEdit,

    LeasePaymentCreate,
    LeasePaymentReport,
    LeasePaymentEdit,

    SaleCreate,
    SaleEdit,

    List1,
  },
  computed: {
    disp() {
      return this.$route.params.disp;
    },
    title() {
      if (this.apoData.state == ApoStates.Default) return 'デモ日程確認待ち';
      if (this.apoData.state == ApoStates.Cancelled) return 'アポキャンセル';
      if (this.apoData.state == ApoStates.DemoScheduleConfirmed) return 'デモ結果報告待ち';
      if (this.apoData.state == ApoStates.Missed) return 'アポ入力ミス';
      //
      if (this.demoData.state == DemoResultTypes.OK) return '受注';
      if (this.demoData.state == DemoResultTypes.NG) return 'デモ結果NG';
      if (this.demoData.state == DemoResultTypes.Wait) return '返事待ち';
      if (this.demoData.state == DemoResultTypes.ReDemo) return '再デモ';
      if (this.demoData.state == DemoResultTypes.Cancelled) return 'キャンセル';
      if (this.demoData.state == DemoResultTypes.Missed) return 'デモ入力ミス';

      if (this.demoData.state == DemoResultTypes.NoInvoiced) return '請求書未発行';
      if (this.demoData.state == DemoResultTypes.NoDeposited) return '入金待ち';
      if (this.demoData.state == DemoResultTypes.Deposited) return '入金済み';

      //
      if (this.demoData.state == DemoResultTypes.Examination) return 'リース審査中';
      if (this.leasePaymentData.state == LeasePaymentStates.OK) return 'リース審査OK';
      if (this.leasePaymentData.state == LeasePaymentStates.NG) return 'リースNG';
      if (this.leasePaymentData.state == LeasePaymentStates.AllNG) return 'リース全NG';
      //
      if (this.demoData.state == DemoResultTypes.UnHPCoverageScheduled) return '導入日未定';
      if (this.hpCoverageData.state == HPCoverageStates.Scheduled) return '導入日決定物件';
      if (this.hpCoverageData.state == HPCoverageStates.Complete) return '導入・取材完了';

      //
      if (this.leasePaymentData.startState == LeasePaymentStartStates.NotStart) return 'リース開始待ち';
      if (this.leasePaymentData.startState == LeasePaymentStartStates.NotComplete) return '入金待ち';
      if ((this.leasePaymentData.startState & (LeasePaymentStartStates.CompleteOK | LeasePaymentStartStates.Rental)) != 0) return '入金済';
      if (this.leasePaymentData.startState == LeasePaymentStartStates.CompleteNG) return '入金NG';

      //
      if (this.contractDocumentData.state == ContractDocumentStates.UnCollected) return '未提出書類';
      if (this.contractDocumentData.state == ContractDocumentStates.Collected) return '書類提出完了';

      return '';
    },

    ApoStates() {
      return ApoStates;
    },
    DemoResultTypes() {
      return DemoResultTypes;
    },

    DemoSaleTypes() {
      return DemoSaleTypes;
    },

    HPCoverageStates() {
      return HPCoverageStates;
    },

    LeasePaymentStates() {
      return LeasePaymentStates;
    },
    LeasePaymentStartStates() {
      return LeasePaymentStartStates;
    },
    ContractDocumentStates() {
      return ContractDocumentStates;
    },
    FilterDisps() {
      return FilterDisps;
    },

    HPCoverageTitle() {
      switch (this.hpCoverageData.state) {
        case HPCoverageStates.Default:
        case HPCoverageStates.Scheduled:
          return [
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'demo.facility.name' },
            { text: '支店', value: 'demo.branch.name' },
            { text: 'デモ日', value: 'demo.date' },
            { text: '取材予定日', value: 'scheduleDate' },

            { text: '操作', value: 'action' },
          ];

        default:
          return [
            { text: 'コード', value: 'code' },
            { text: '宿名', value: 'demo.facility.name' },
            { text: '支店', value: 'demo.branch.name' },
            { text: 'デモ日', value: 'demo.date' },
            { text: '導入取材完了日', value: 'date' },

            { text: '操作', value: 'action' },
          ];
      }
    },
    filterDisp() {
      // if (this.apoData.state) {
      //   return FilterDisps.FacilityName;
      // }
      // if (this.demoData.state) {
      //   return FilterDisps.FacilityName | FilterDisps.ApoBranch | FilterDisps.DemoBranch | FilterDisps.ApoDates | FilterDisps.DemoDates;
      // }
      // if (this.leasePaymentData.state)
      //   return (
      //     FilterDisps.FacilityName |
      //     FilterDisps.ApoBranch |
      //     FilterDisps.DemoBranch |
      //     FilterDisps.ApoDates |
      //     FilterDisps.DemoDates |
      //     FilterDisps.HpCoverageDates
      //   );
      // return 0;
      // TODO プロセス未選択でも検索次第でプロセス中のタスク数が変わり、フィルタとしてやくだっているのとりあえず全部だすようにしておく
      return FilterDisps.All;
    },
  },

  data: () => ({
    drawer: true,
    progress:{

      loading:true,
      message:null,
    },

    stateDatas: [],

    datas2: [],

    flow: {
      show: true,
      type: 1,
    },

    facilityData: {
      show: false,
      facilityCode: null,
    },

    /** アポイントについて */
    apoData: {
      loading: true,
      state: null,
      datas: [],
    },

    apoDemoSchedule: {
      show: false,
      apoCode: null,
    },
    apoEditor: {
      show: false,
      apoCode: null,
    },

    /** デモについて */
    demoData: {
      show: false,
      loading: true,
      state: null,
      datas: [],
    },

    demoCreator: {
      show: false,
      apoCode: null,
    },

    demoEditor: {
      show: false,
      demoCode: null,
    },

    demoSubmitPreExamination: {
      show: false,
      demoCode: null,
    },

    hpCoverageData: {
      loading: true,
      state: null,
      datas: [],
    },

    /** リースについて */
    leasePaymentData: {
      loading: true,
      state: null,
      startState: null,
      datas: [],
      // datas2: [], // 導入取材が完了したデータ 20220325廃止
    },

    /** 未提出書類 */
    contractDocumentData: {
      loading: true,
      state: null,
      datas: [],
    },

    demoContractDocumentEditor: {
      show: false,
      demoCode: null,
    },

    hpCoverageCreator: {
      show: false,
      demoCode: null,
    },

    hpCoverageScheduleEditor: {
      show: false,
      hpCoverageCode: null,
      isChangeSchedule: false,
    },

    hpCoverageEditor: {
      show: false,
      hpCoverageCode: null,
    },

    leasePaymentCreator: {
      show: false,
      demoCode: null,
    },

    leasePaymentEditor: {
      show: false,
      demoCode: null,
      viewStartState: 0,
    },

    leasePaymentReport: {
      show: false,
      demoCode: null,
    },

    saleCreator: {
      show: false,
      demoCode: null,
    },

    saleEditor: {
      show: false,
      demoCode: null,
    },

    dataFilter: {
      show: false,
      data: {
        facilityName: null,
        apoBranch: null,
        demoBranch: null,
        apoDates: null,
        demoDates: null,
        hpCoverageScheduleDates: null,
        hpCoverageDates: null,
      },
    },
  }),

  watch: {},

  methods: {
    async load() {
      this.progress.loading = true;
      //

      {
        // FIXME とりあえずの負荷軽減対策
        this.apoData.datas = [];
        for (let i = 0; i < 30; i++) {
          //
          this.progress.message = `1/4 : apo loading... ${i+1}/30`;

          let success = await this.get('apos',  { page: i });
            let data = success.data;
            if(data.length==0)break;
            // this.apoData.datas = data;
            this.apoData.datas = this.apoData.datas.concat(data);

          // this.get('apos',  { page: i }).then((success) => {
          //   let data = success.data;
          //   // this.apoData.datas = data;
          //   this.apoData.datas = this.apoData.datas.concat(data);

          // });
          this.apoData.loading = false;
        }
      }

      //
      {
        // FIXME とりあえずの負荷軽減対策
        this.demoData.datas = [];
        this.contractDocumentData.datas = [];

        for (let i = 0; i < 30; i++) {
          //
          this.progress.message = `2/4 : demo loading... ${i+1}/30`;

          const success = await this.get('demos', { page: i });
          let data = success.data;
            if(data.length==0)break;
          this.demoData.datas = this.demoData.datas.concat(data);
          // 未提出書類はデモデータからとる
          this.contractDocumentData.datas = this.contractDocumentData.datas.concat(data);
        }
          this.contractDocumentData.loading = false;
          this.demoData.loading = false;
        console.log("this.demoData.datas.length", this.demoData.datas.length)

        // this.get('demos').then((success) => {
        //     let data = success.data;
        //     console.log("datas" ,data.length)
        // });
      }


      {
        // FIXME とりあえずの負荷軽減対策
        this.hpCoverageData.datas = [];
        for (let i = 0; i < 20; i++) {
          //
          this.progress.message = `3/4 : hpCoverage loading... ${i+1}/20`;

          let success = await this.get('hpCoverages', {page: i})
            let data = success.data;
            if(data.length==0)break;
            this.hpCoverageData.datas = this.hpCoverageData.datas.concat(data);
          // this.get('hpCoverages', {page: i}).then((success) => {
          //   let data = success.data;
          //   // this.hpCoverageData.datas = data;
          //   this.hpCoverageData.datas = this.hpCoverageData.datas.concat(data);

          // });
          this.hpCoverageData.loading = false;
        }
      }
      //
      
      {
        // FIXME とりあえずの負荷軽減対策
        this.leasePaymentData.datas = [];
        for (let i = 0; i < 20; i++) {
          //
          this.progress.message = `4/4 : leasePayment loading... ${i+1}/20`;

          let success = await this.get('leasePayments', {page: i})
          let data = success.data;
            if(data.length==0)break;
          this.leasePaymentData.datas = this.leasePaymentData.datas.concat(data);

          // this.get('leasePayments', {page: i}).then((success) => {
          //   let data = success.data;
          //   // this.leasePaymentData.datas = data;
          //   this.leasePaymentData.datas = this.leasePaymentData.datas.concat(data);
          //   // すべて導入取材が完了したものをベースにする->20220325 取材導入が完了していなくても進めるように変更
          //   // this.leasePaymentData.datas2 = data.filter((a) => a.demo.hpCoverage != null && a.demo.hpCoverage.date != null);
          // });
          this.leasePaymentData.loading = false;
        }
      }

      // 未提出書類はデモデータからとる
      // this.get('demos', {}).then((success) => {
      //   let data = success.data;
      //   this.contractDocumentData.datas = data;
      //   this.contractDocumentData.loading = false;
      // });
      this.progress.loading = false;

      //
    },

    onLoaded() {
      // 全データを読み込む
      this.load();
    },

    getApoDatas(state) {
      let datas = this.apoData.datas;
      // 関連がややこしくならないようswitchでとらないようにする
      if (state == ApoStates.Default) {
        datas = datas
          .filter((a) => a.state == ApoStates.Default)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      }

      if (state == ApoStates.Cancelled) {
        datas = datas
          .filter((a) => a.state == ApoStates.Cancelled)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      }

      if (state == ApoStates.DemoScheduleConfirmed) {
        datas = datas
          .filter((a) => a.state == ApoStates.DemoScheduleConfirmed)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      }

      if (state == ApoStates.Missed) {
        datas = datas.filter((a) => a.isMissed).filter((a) => !a.isDummy);
      }

      // dataFilter
      datas = this.filterApoDatas(datas);

      return datas;
    },

    getDemoDatas(state) {
      let datas = this.demoData.datas;
      // 関連がややこしくならないようswitchでとらないようにする
      if (state == DemoResultTypes.OK)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);
      if (state == DemoResultTypes.NG)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.NG)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);
      if (state == DemoResultTypes.Wait)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.Wait)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      // if (this.demoData.state == DemoResultTypes.Cancelled) datas = datas.filter((a) => a.resultType == DemoResultTypes.Cancelled).filter((a) => !a.isDummy);
      if (state == DemoResultTypes.Cancelled)
        datas = datas
          .filter((a) => a.isCancelled) // キャンセルされたものを対象
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == DemoResultTypes.ReDemo)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.ReDemo)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      if (state == DemoResultTypes.UnHPCoverageScheduled) {
        datas = datas
          .filter(
            (a) =>
              (a.leasePayment != null && a.leasePayment.state == LeasePaymentStates.OK) ||
              (a.saleType & (DemoSaleTypes.Rental & DemoSaleTypes.Cash)) != 0
          )
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => a.hpCoverage == null || (a.hpCoverage != null && a.hpCoverage.state == HPCoverageStates.Default))
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);
      }

      if (state == DemoResultTypes.PreExamination)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => !a.isSubmitPreExaminationDate)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      if (state == DemoResultTypes.Examination)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => a.saleType == 1) // リースのもののみ
          .filter((a) => a.isSubmitPreExaminationDate)
          .filter((a) => !a.hasLeasePayment || (a.hasLeasePayment && !a.hasLeasePaymentReport)) // リース結果を持たないか、持っていても「報告」にチェックがない
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      // 請求書未発行
      if (state == DemoResultTypes.NoInvoiced)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => a.needDemoSale) // 請求書発行が必要なデモか？
          .filter((a) => !a.isInvoiced) // 請求書発行済みか？
          .filter((a) => !a.isDeposited) // 入金済みか？
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      // 入金待ち
      if (state == DemoResultTypes.NoDeposited)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => a.needDemoSale) // 請求書発行が必要なデモか？
          .filter((a) => a.isInvoiced) // 請求書発行済みか？
          .filter((a) => !a.isDeposited) // 入金済みか？
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      // 入金済み
      if (state == DemoResultTypes.Deposited)
        datas = datas
          .filter((a) => a.resultType == DemoResultTypes.OK)
          .filter((a) => a.needDemoSale) // 請求書発行が必要なデモか？
          .filter((a) => a.isInvoiced) // 請求書発行済みか？
          .filter((a) => a.isDeposited) // 入金済みか？
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy)
          .filter((a) => !a.isCancelled);

      if (state == DemoResultTypes.Missed) {
        datas = datas.filter((a) => a.isMissed).filter((a) => !a.isDummy);
      }

      // dataFilter
      datas = this.filterDemoDatas(datas);

      return datas;
    },

    getLeasePaymentDatas(state, startState) {
      // 開始ステータスを優先（上記はまだreturn させていないが、これはこのブロック内でreturnさせている)
      if (startState != null) {
        //
        // let leasePaymentDatas = this.leasePaymentData.datas2;
        let leasePaymentDatas = this.leasePaymentData.datas;

        // dataFilter
        leasePaymentDatas = this.filterLeasePaymentDatas(leasePaymentDatas);

        //
        if ((startState & LeasePaymentStartStates.UnCollected) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.UnCollected) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.Collected) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.Collected) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.Submited) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.Submited) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.StartOK) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.StartOK) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.StartNG) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.StartNG) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.CompleteOK) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.CompleteOK) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.CompleteNG) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.CompleteNG) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }
        if ((startState & LeasePaymentStartStates.Rental) != 0) {
          return leasePaymentDatas
            .filter((a) => (a.startState & LeasePaymentStartStates.Rental) != 0)
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }

        // リース開始待ち
        if ((startState & LeasePaymentStartStates.NotStart) != 0) {
          return leasePaymentDatas
            .filter(
              (a) =>
                // 回収済みを登録するプロセスをのぞいたため、Submited不問とし、リースOKを対象とする
                a.state == LeasePaymentStates.OK &&
                a.startState != LeasePaymentStartStates.StartOK &&
                a.startState != LeasePaymentStartStates.CompleteOK
            )
            .filter((a) => a.demo != null && a.demo.hpCoverage != null && a.demo.hpCoverage.state == HPCoverageStates.Complete) // 609 導入済みも条件に追加
            .filter((a) => a.demo != null && !a.demo.isCancelled) // 610 受注後キャンセルを表示させない導入済みも条件に追加

            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }

        if ((startState & LeasePaymentStartStates.NotComplete) != 0) {
          return leasePaymentDatas
            .filter(
              (a) =>
                // 回収済みを登録するプロセスをのぞいたため、Submited不問とし、リースOKを対象とする
                a.state == LeasePaymentStates.OK &&
                a.startState == LeasePaymentStartStates.StartOK &&
                a.startState != LeasePaymentStartStates.CompleteOK
            )
            .filter((a) => !a.isMissed)
            .filter((a) => !a.isDummy);
        }

        // それ以外なら空
        return [];
      }

      //
      let datas = this.leasePaymentData.datas;
      // 関連がややこしくならないようswitchでとらないようにする
      if (state == LeasePaymentStates.Examination)
        datas = datas
          .filter((a) => a.state == LeasePaymentStates.Default)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == LeasePaymentStates.OK)
        datas = datas
          .filter((a) => a.state == LeasePaymentStates.OK)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);

      if (state == LeasePaymentStates.AllNG) {
        datas = datas
          .filter((a) => a.state == LeasePaymentStates.AllNG)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      } else if (state == LeasePaymentStates.NG) {
        datas = datas
          .filter((a) => a.state == LeasePaymentStates.NG)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      }

      // dataFilter
      datas = this.filterLeasePaymentDatas(datas);
      //
      return datas;
    },

    getHPCoverageDatas(state) {
      let datas = this.hpCoverageData.datas;
      // 関連がややこしくならないようswitchでとらないようにする
      if (state == HPCoverageStates.Default)
        datas = datas
          .filter((a) => a.state == HPCoverageStates.Default)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == HPCoverageStates.Scheduled)
        datas = datas
          .filter((a) => a.state == HPCoverageStates.Scheduled)
          .filter((a) => a.demo != null && !a.demo.isCancelled) // 610 受注後キャンセルを表示させない導入済みも条件に追加

          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == HPCoverageStates.Complete)
        datas = datas
          .filter((a) => a.state == HPCoverageStates.Complete)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == HPCoverageStates.Cancelled)
        datas = datas
          .filter((a) => a.state == HPCoverageStates.Cancelled)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);
      if (state == HPCoverageStates.Retake)
        datas = datas
          .filter((a) => a.state == HPCoverageStates.Retake)
          .filter((a) => !a.isMissed)
          .filter((a) => !a.isDummy);

      // dataFilter
      datas = this.filterHpCoverageDatas(datas);

      return datas;
    },

    getContractDocumentDatas(state) {
      let datas = this.contractDocumentData.datas;
      datas = datas.filter((a) => a.resultType == DemoResultTypes.OK);

      if (state == ContractDocumentStates.UnCollected) datas = datas.filter((a) => a.unContractDocumentCount > 0);
      if (state == ContractDocumentStates.Collected) datas = datas.filter((a) => a.unContractDocumentCount == 0);
      //
      datas = datas.filter((a) => !a.isMissed).filter((a) => !a.isDummy);

      // dataFilter
      datas = this.filterDemoDatas(datas);

      return datas.map((a) => {
        let facility = {
          code: null,
          name: null,
          areaName: null,
          pref: {
            name: null,
          },
          isDummy: false,
        };
        if (a.facility != null) {
          facility.code = a.facility.code;
          facility.name = a.facility.name;
          facility.areaName = a.facility.areaName;
          if (a.facility.pref != null) facility.pref = a.facility.pref;
          facility.isDummy = a.facility.isDummy;
        }

        return {
          code: a.code,
          facility: facility,
          date: a.date,
          saleType: a.saleType,
          contractDocumentType: a.contractDocumentType,
          unContractDocumentType: a.unContractDocumentType,
          meta: a.meta,
          system: this.hasFlag(a.unContractDocumentType, 1),
          lease: this.hasFlag(a.unContractDocumentType, 1 << 1),
          delivery: this.hasFlag(a.unContractDocumentType, 1 << 2),
          support: this.hasFlag(a.unContractDocumentType, 1 << 3),
          sukesan: this.hasFlag(a.unContractDocumentType, 1 << 4),
          wideNet: this.hasFlag(a.unContractDocumentType, 1 << 5),
        };
      });
    },

    onApoEdit(apoCode) {
      this.apoEditor.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailApo(apoCode);
    },

    onApoDemoSchedule(apoCode) {
      this.apoDemoSchedule.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailApo(apoCode);
    },

    //
    onDemoCreate(demoCode) {
      this.demoCreator.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailDemo(demoCode);
    },

    onDemoEdit(demoCode) {
      this.demoEditor.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailDemo(demoCode);
    },

    onDemoContractDocumentEdit() {
      this.demoContractDocumentEditor.show = false;
      this.load();

      // メールは送らない
    },

    onDemoSubmitPreExamination(demoCode) {
      this.demoSubmitPreExamination.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailDemo(demoCode);
    },

    onHPCoverageCreate(hpCoverageCode) {
      this.hpCoverageCreator.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailHPCoverage(hpCoverageCode);
    },

    onHPCoverageScheduleEdit(hpCoverageCode) {
      this.hpCoverageScheduleEditor.show = false;
      this.load();

      this.sendMailHPCoverage(hpCoverageCode);
    },

    onHPCoverageScheduleEditClear() {
      this.hpCoverageScheduleEditor.show = false;
      this.load();
    },

    onHPCoverageEdit(hpCoverageCode) {
      this.hpCoverageEditor.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailHPCoverage(hpCoverageCode);
    },

    onLeasePaymentCreate(hpCoverageCode) {
      this.leasePaymentCreator.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailLeasePayment(hpCoverageCode);
    },

    onLeasePaymentReport(leasePaymentCode) {
      this.leasePaymentReport.show = false;
      this.load();

      // メール送信(確認あり)
      this.sendMailLeasePayment(leasePaymentCode);
    },

    onLeasePaymentEdit(leasePaymentCode) {
      this.leasePaymentEditor.show = false;
      this.load();
      // メール送信(確認あり)
      this.sendMailLeasePayment(leasePaymentCode);
    },

    onSaleCreate() {
      this.saleCreator.show = false;
      this.load();
      // メールは送らない
    },
    onSaleEdit() {
      this.saleEditor.show = false;
      this.load();
      // メールは送らない
    },

    //
    onDataFilterCommit(data) {
      // this.dataFilter.data = data;
      // クローンしないと検索条件のモデルと連動されてしまう
      this.dataFilter.data = Object.assign({}, data);
    },

    filterApoDatas(datas) {
      if (this.dataFilter.data.facilityName) {
        datas = datas.filter((a) => a.facility == null || a.facility.name.indexOf(this.dataFilter.data.facilityName) != -1);
      }
      if (this.dataFilter.data.apoBranch) {
        datas = datas.filter((a) => a.branch.id == this.dataFilter.data.apoBranch.id);
      }

      {
        if (Array.isArray(this.dataFilter.data.apoDates) && this.dataFilter.data.apoDates.length >= 2) {
          let val1 = this.dataFilter.data.apoDates[0];
          let val2 = this.dataFilter.data.apoDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter((a) => this.$moment(a.date).isBetween(start, end, undefined, '[]'));
          }
        }
      }

      //
      return datas;
    },

    filterDemoDatas(datas) {
      if (this.dataFilter.data.facilityName) {
        datas = datas.filter((a) => a.facility == null || a.facility.name.indexOf(this.dataFilter.data.facilityName) != -1);
      }
      if (this.dataFilter.data.demoBranch) {
        datas = datas.filter((a) => a.branch.id == this.dataFilter.data.demoBranch.id);
      }

      {
        if (Array.isArray(this.dataFilter.data.demoDates) && this.dataFilter.data.demoDates.length >= 2) {
          let val1 = this.dataFilter.data.demoDates[0];
          let val2 = this.dataFilter.data.demoDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter((a) => this.$moment(a.date).isBetween(start, end, undefined, '[]'));
          }
        }
      }

      //
      return datas;
    },

    filterLeasePaymentDatas(datas) {
      if (this.dataFilter.data.facilityName) {
        datas = datas.filter((a) => a.facility.name.indexOf(this.dataFilter.data.facilityName) != -1);
      }
      if (this.dataFilter.data.demoBranch) {
        datas = datas.filter((a) => a.demo == null || a.demo.branch.id == this.dataFilter.data.demoBranch.id);
      }

      {
        if (Array.isArray(this.dataFilter.data.demoDates) && this.dataFilter.data.demoDates.length >= 2) {
          let val1 = this.dataFilter.data.demoDates[0];
          let val2 = this.dataFilter.data.demoDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter((a) => this.$moment(a.demo.date).isBetween(start, end, undefined, '[]'));
          }
        }
      }
      //
      {
        if (Array.isArray(this.dataFilter.data.hpCoverageScheduleDates) && this.dataFilter.data.hpCoverageScheduleDates.length >= 2) {
          let val1 = this.dataFilter.data.hpCoverageScheduleDates[0];
          let val2 = this.dataFilter.data.hpCoverageScheduleDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter(
              (a) => a.demo.hpCoverage == null || this.$moment(a.demo.hpCoverage.scheduleDate).isBetween(start, end, undefined, '[]')
            );
          }
        }
      }
      //
      {
        if (Array.isArray(this.dataFilter.data.hpCoverageDates) && this.dataFilter.data.hpCoverageDates.length >= 2) {
          let val1 = this.dataFilter.data.hpCoverageDates[0];
          let val2 = this.dataFilter.data.hpCoverageDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter(
              (a) => a.demo.hpCoverage == null || this.$moment(a.demo.hpCoverage.date).isBetween(start, end, undefined, '[]')
            );
          }
        }
      }
      //
      return datas;
    },

    filterHpCoverageDatas(datas) {
      if (this.dataFilter.data.facilityName) {
        datas = datas.filter((a) => a.demo.facility.name.indexOf(this.dataFilter.data.facilityName) != -1);
      }
      if (this.dataFilter.data.demoBranch) {
        datas = datas.filter((a) => a.demo.branch.id == this.dataFilter.data.demoBranch.id);
      }

      {
        if (Array.isArray(this.dataFilter.data.demoDates) && this.dataFilter.data.demoDates.length >= 2) {
          let val1 = this.dataFilter.data.demoDates[0];
          let val2 = this.dataFilter.data.demoDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter((a) => this.$moment(a.demo.date).isBetween(start, end, undefined, '[]'));
          }
        }
      }
      //
      {
        if (Array.isArray(this.dataFilter.data.hpCoverageScheduleDates) && this.dataFilter.data.hpCoverageScheduleDates.length >= 2) {
          let val1 = this.dataFilter.data.hpCoverageScheduleDates[0];
          let val2 = this.dataFilter.data.hpCoverageScheduleDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter(
              (a) => a.demo.hpCoverage == null || this.$moment(a.demo.hpCoverage.scheduleDate).isBetween(start, end, undefined, '[]')
            );
          }
        }
      }
      //
      {
        if (Array.isArray(this.dataFilter.data.hpCoverageDates) && this.dataFilter.data.hpCoverageDates.length >= 2) {
          let val1 = this.dataFilter.data.hpCoverageDates[0];
          let val2 = this.dataFilter.data.hpCoverageDates[1];
          if (val1 && val2) {
            let start = this.$moment(val1);
            let end = this.$moment(val2);
            datas = datas.filter((a) => this.$moment(a.date).isBetween(start, end, undefined, '[]'));
          }
        }
      }
      //
      return datas;
    },

    onCSVDownload() {
      console.log('onCSVDownload');

      // const dateStart = this.$moment(this.dateStart).toDate();
      // const dateEnd = this.$moment(this.dateEnd).toDate();

      // FIXME: 仕様が決まるまで期間は絞らない
      const dateStart = null;
      const dateEnd = null;

      this.get('analyse/datas/csv', { dateStart: dateStart, dateEnd: dateEnd }).then((success) => {
        const base64 = success.data;

        console.log({ base64 });

        download(base64, 'text/csv', 'data.csv');
      });
    },
  },
  created() {
    this.onLoaded();
  },
};
</script>