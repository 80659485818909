var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" 提出書類編集 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[(_vm.apo)?[_c('ApoResult',{attrs:{"apo":_vm.apo}})]:_vm._e(),(_vm.demo)?[_c('DemoResult',{attrs:{"demo":_vm.demo}})]:_vm._e(),_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',{staticClass:"table-primary"},[_c('th',{attrs:{"width":"50"}},[_vm._v(" ")]),_c('th',[_vm._v("書類名")]),_c('th',{attrs:{"width":"180"}},[_vm._v("日付")]),_c('th',[_vm._v("不要")])])]),_c('tbody',[(!_vm.creator.system.disabled)?_c('tr',[_c('th',[(_vm.creator.system.submitDate != null || _vm.creator.system.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.system.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" あすなろシステム契約書 ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.system.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.system.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.system.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.system.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.system.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.system, "submitDate", $$v)},expression:"creator.system.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.system.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.system, "ignoreSubmitDate", $$v)},expression:"creator.system.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e(),(!_vm.creator.lease.disabled)?_c('tr',[_c('th',[(_vm.creator.lease.submitDate != null || _vm.creator.lease.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.lease.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" リース契約書 ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.lease.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.lease.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.lease.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.lease.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.lease.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.lease, "submitDate", $$v)},expression:"creator.lease.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.lease.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.lease, "ignoreSubmitDate", $$v)},expression:"creator.lease.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e(),(!_vm.creator.delivery.disabled)?_c('tr',[_c('th',[(_vm.creator.delivery.submitDate != null || _vm.creator.delivery.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.delivery.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" 納品確認書 ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.delivery.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.delivery.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.delivery.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.delivery.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.delivery.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.delivery, "submitDate", $$v)},expression:"creator.delivery.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.delivery.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.delivery, "ignoreSubmitDate", $$v)},expression:"creator.delivery.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e(),(!_vm.creator.support.disabled)?_c('tr',[_c('th',[(_vm.creator.support.submitDate != null || _vm.creator.support.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.support.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" サポート規約 ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.support.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.support.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.support.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.support.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.support.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.support, "submitDate", $$v)},expression:"creator.support.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.support.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.support, "ignoreSubmitDate", $$v)},expression:"creator.support.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e(),(!_vm.creator.sukesan.disabled)?_c('tr',[_c('th',[(_vm.creator.sukesan.submitDate != null || _vm.creator.sukesan.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.sukesan.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" すけさん契約申込書 ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.sukesan.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.sukesan.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.sukesan.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.sukesan.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.sukesan.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.sukesan, "submitDate", $$v)},expression:"creator.sukesan.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.sukesan.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.sukesan, "ignoreSubmitDate", $$v)},expression:"creator.sukesan.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e(),(!_vm.creator.wideNet.disabled)?_c('tr',[_c('th',[(_vm.creator.wideNet.submitDate != null || _vm.creator.wideNet.ignoreSubmitDate)?_c('v-icon',{attrs:{"color":_vm.creator.wideNet.ignoreSubmitDate ? 'blue' : 'success'},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_vm._e()],1),_c('th',[_c('v-sheet',[_vm._v(" ワイドネット ")])],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[(_vm.creator.wideNet.ignoreSubmitDate)?[_c('span',{staticClass:"blue-grey--text text--darken-1 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.creator.wideNet.submitDate))+" ")])]:[_c('validation-provider',{attrs:{"name":"業務回収日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"業務回収日","error-messages":errors,"hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.creator.wideNet.submitDate))+" ")]),_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.creator.wideNet.submitDate = null}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,true),model:{value:(_vm.creator.wideNet.submitDate),callback:function ($$v) {_vm.$set(_vm.creator.wideNet, "submitDate", $$v)},expression:"creator.wideNet.submitDate"}})]}}],null,true)})]],2)],1),_c('td',[_c('v-sheet',{attrs:{"height":"25"}},[_c('validation-provider',{attrs:{"name":"不要","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.creator.wideNet.ignoreSubmitDate),callback:function ($$v) {_vm.$set(_vm.creator.wideNet, "ignoreSubmitDate", $$v)},expression:"creator.wideNet.ignoreSubmitDate"}})]}}],null,true)})],1)],1)]):_vm._e()])]),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.creator.memo),callback:function ($$v) {_vm.$set(_vm.creator, "memo", $$v)},expression:"creator.memo"}})]}}],null,true)})],2):_vm._e()]}}])})],1),_c('v-card-actions',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.demoCode))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }