<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      リース審査結果登録
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.leaseCompanyType)"
                      >{{ isEmptyValue(creator.apoType) ? 'リース審査結果を登録してください' : 'リース審査結果' }}
                      <span v-if="creator.state != null" class="success--text">
                        <v-chip :color="creator.state == LeasePaymentStates.OK ? 'success' : 'warning'" small>
                          {{ creator.state | leasePaymentState }}
                        </v-chip>
                        <span>{{ creator.leaseCompanyType | leasePaymentCompanyType }}</span>
                        <span class="ml-2">審査結果日: {{ creator.examinationResultDate | date }}</span>
                      </span>
                    </XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.leasePaymentCompanyType(creator.leaseCompanyType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-alert border="left" colored-border type="info" outlined>報告対象とする審査結果にチェックを付けてください </v-alert>
                      <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(result, i) in examinationResult.results" :key="i">
                          <v-expansion-panel-header>
                            <div>
                              <v-checkbox
                                v-model="result.report"
                                dense
                                @click.stop="
                                  examinationResult.results
                                    .filter((a) => a.leaseCompanyType != result.leaseCompanyType)
                                    .forEach((a) => (a.report = false))
                                "
                              >
                                <template v-slot:label>
                                  <!-- OK/NGのみ出す -->
                                  <v-chip
                                    v-if="[LeasePaymentStates.OK, LeasePaymentStates.NG].includes(result.state)"
                                    :color="result.state == LeasePaymentStates.OK ? 'success' : 'warning'"
                                    small
                                  >
                                    {{ result.state | leasePaymentState }}
                                  </v-chip>
                                  {{ result.leaseCompanyType | leasePaymentCompanyType }}

                                  <span class="text-caption">
                                    <!-- <span v-if="result.examinationDate != null">提出: {{ result.examinationDate | date }}</span> -->
                                    <span v-if="result.examinationResultDate != null"
                                      >結果日: {{ result.examinationResultDate | date }}</span
                                    >
                                  </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text>
                                <!-- <XFieldSet class="mb-4" label="リース会社審査提出日">
                                  <DateTime label="審査提出日" v-model="result.examinationDate" hideTime>
                                    <template v-slot="{ click }"
                                      ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                        >{{ result.examinationDate | date }}
                                      </v-btn>
                                    </template>
                                  </DateTime>
                                </XFieldSet> -->

                                <XFieldSet class="mb-4" label="審査結果日">
                                  <DateTime label="審査結果日" v-model="result.examinationResultDate" hideTime>
                                    <template v-slot="{ click }"
                                      ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                        >{{ result.examinationResultDate | date }}
                                      </v-btn>
                                    </template>
                                  </DateTime>
                                </XFieldSet>

                                <v-select
                                  v-model="result.state"
                                  :items="[
                                    {
                                      text: $options.filters.leasePaymentState(LeasePaymentStates.Default),
                                      value: LeasePaymentStates.Default,
                                    },
                                    { text: $options.filters.leasePaymentState(LeasePaymentStates.OK), value: LeasePaymentStates.OK },
                                    { text: $options.filters.leasePaymentState(LeasePaymentStates.NG), value: LeasePaymentStates.NG },
                                  ]"
                                  label="*審査結果"
                                  :rules="[(v) => !!v || '審査結果は必須です']"
                                  required
                                  outlined
                                  dense
                                />

                                <v-textarea outlined v-model="result.memo" label="備考" counter :maxlength="1000" clearable />
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <!-- <validation-provider v-slot="{ errors }" name="リース会社" rules="required">
                        <v-select
                          v-model="leaseCompanyType"
                          :items="[
                            { text: $options.filters.leasePaymentCompanyType(1), value: 1 },
                            { text: $options.filters.leasePaymentCompanyType(1 << 1), value: 1 << 1 },
                            { text: $options.filters.leasePaymentCompanyType(1 << 2), value: 1 << 2 },
                            { text: $options.filters.leasePaymentCompanyType(1 << 5), value: 1 << 5 },
                          ]"
                          label="*リース会社"
                          :rules="[(v) => !!v || 'リース会社 は必須です']"
                          :error-messages="errors"
                          required
                          outlined
                          dense
                        />
                      </validation-provider> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="warning"
                        @click="
                          leaseCompanyType = null;
                          creator.leaseCompanyType = null;
                          initExaminationResult();
                        "
                        >クリア
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        @click="
                          // creator.leaseCompanyType = leaseCompanyType;

                          // NGではなく、報告対象としているもの
                          let result = examinationResult.results.find((a) => a.report);
                          if (result != null) {
                            creator.leaseCompanyType = result.leaseCompanyType;
                            creator.examinationResultDate = result.examinationResultDate;
                            creator.state = result.state;
                          } else {
                            creator.leaseCompanyType = null;
                            creator.examinationResultDate = null;
                            creator.state = null;
                          }
                          //
                          creator.examinationResult = examinationResult;

                          expansions++;
                        "
                        >設定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.examinationResultDate)">{{
                      isEmptyValue(creator.examinationResultDate) ? '*審査結果日を登録してください' : '*審査結果日'
                    }}</XCheckLabel>
                    <span
                      v-if="creator.examinationResultDate != null"
                      class="ml-4 success--text"
                      v-text="$options.filters.date(creator.examinationResultDate)"
                    ></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="*審査結果日">
                          <validation-provider v-slot="{ errors }" name="審査結果日" rules="required">
                            <DateTime label="審査結果日" v-model="examinationResultDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ examinationResultDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            examinationResultDate = null;
                            creator.examinationResultDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.examinationResultDate = examinationResultDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel> -->

              <!-- <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.state != null">{{
                      creator.state == null ? '*審査結果を登録してください' : '審査結果'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.leasePaymentState(creator.state)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="審査結果" rules="required">
                          <v-select
                            v-model="state"
                            :items="[
                              { text: $options.filters.leasePaymentState(1 << 2), value: 1 << 2 },
                              { text: $options.filters.leasePaymentState(1 << 3), value: 1 << 3 },
                            ]"
                            label="*審査結果"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                            @change="
                              {
                                // FIXME Editにも同じ処理があるため統合させること
                                let message = meta.ngMessage;
                                if (message == null) message = ``;
                                if (state == 1 << 3) {
                                  message += `${$options.filters.date(new Date())}:`;
                                  message += `${$options.filters.leasePaymentCompanyType(creator.leaseCompanyType)}`;
                                  message += ` NG`;
                                }
                                meta.ngMessage = message;
                              }
                            "
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="NG理由" rules="max:1000">
                          <v-textarea
                            v-if="state == 1 << 3"
                            outlined
                            v-model="meta.ngMessage"
                            label="NG理由"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            state = null;
                            meta.ngMessage = null;
                            creator.state = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.state = state;
                            creator.meta.ngMessage = meta.ngMessage;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel> -->

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.rate)">
                      <!-- 必須かをOKか否かで切り替え -->
                      <span v-if="creator.state == LeasePaymentStates.OK">*</span>
                      <span>{{ isEmptyValue(creator.rate) ? '料率を登録してください' : '料率' }}</span>
                    </XCheckLabel>
                    <span v-if="creator.rate != null" class="ml-4 success--text" v-text="`${creator.rate}`"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <!-- 必須かをOKか否かで切り替え -->
                      <v-card-text>
                        <validation-provider
                          v-slot="{ errors }"
                          name="料率"
                          :rules="creator.state == LeasePaymentStates.OK ? 'required|max:100' : 'max:100'"
                        >
                          <v-combobox
                            v-model.number="rate"
                            :items="[1.83, 1.82]"
                            label="料率"
                            :hint="`現在の売上見込み金額は ${$options.filters.priceJP(demo.prospectAmount)} です`"
                            persistent-hint
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                            dense
                          ></v-combobox>
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            rate = null;
                            creator.rate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.rate = rate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="creator.residualBonds != null">{{
                    creator.residualBonds == null ? '残債、他社買取、サービス品を登録してください' : '残債、他社買取、サービス品'
                  }}</XCheckLabel>

                  <div>
                    <span v-if="creator.residualBonds != null" class="ml-4 success--text"> 残債:{{ creator.residualBonds | priceJP }}</span>
                    <span v-if="creator.underwritingBonds != null" class="ml-4 success--text">
                      他社買取:{{ creator.underwritingBonds | priceJP }}</span
                    >
                    <span v-if="creator.complimentaryBonds != null" class="ml-4 success--text">
                      サービス品:{{ creator.complimentaryBonds | priceJP }}</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="残債" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="residualBonds"
                            label="残債"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="他社買取" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="underwritingBonds"
                            label="他社買取"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="サービス品" rules="">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="complimentaryBonds"
                            label="サービス品"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            residualBonds = null;
                            underwritingBonds = null;
                            complimentaryBonds = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span>備考: </span>
                    <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="タイトル" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from '@/components/demos/Result';

import { LeasePaymentCompanyTypes, LeasePaymentStates } from '@/types';

export default {
  components: {
    ApoResult,
    DemoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    LeasePaymentCompanyTypes() {
      return LeasePaymentCompanyTypes;
    },

    LeasePaymentStates() {
      return LeasePaymentStates;
    },
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    facilities() {
      return [];
    },
    canCommit() {
      // if (this.isEmptyValue(this.creator.leaseCompanyType)) return false;
      // // いずれのリース会社にも報告対象チェックが入っていない
      // if (this.creator.examinationResult.results.filter((a) => a.report).length == 0) return false;
      // if (this.isEmptyValue(this.creator.examinationResultDate)) return false;
      // if (this.isEmptyValue(this.creator.state)) return false;
      // if (this.creator.state == LeasePaymentStates.OK) {
      //   if (this.isEmptyValue(this.creator.rate)) return false;
      // }
      return true;
    },
  },
  data: () => ({
    //
    initializing: true,

    expansions: true,

    apo: null,
    demo: null,
    //
    leaseCompanyType: null,

    examinationResultDate: new Date(),
    state: null,
    rate: null,
    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,

    memo: null,

    meta: {
      ngMessage: null,
    },
    examinationResult: {
      results: [],
    },

    creator: {
      loading: false,

      leaseCompanyType: null,

      examinationResultDate: null,
      state: null,
      rate: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,
      memo: null,

      meta: {
        ngMessage: null,
      },
      examinationResult: {
        results: [],
      },
    },
  }),
  methods: {
    init() {
      //
      //
      this.expansions = null;
      //
      this.apo = null;
      this.demo = null;
      //
      //
      this.leaseCompanyType = null;
      this.examinationResultDate = null;
      this.state = null;
      this.rate = null;
      this.residualBonds = null;
      this.underwritingBonds = null;
      this.complimentaryBonds = null;
      this.memo = null;
      this.meta = {
        ngMessage: null,
      };

      this.creator = {
        leaseCompanyType: null,

        examinationResultDate: null,
        state: null,
        rate: null,
        residualBonds: null,
        underwritingBonds: null,
        complimentaryBonds: null,
        memo: null,

        meta: {
          ngMessage: null,
        },
        examinationResult: {
          results: [],
        },
      };
      //
      this.initExaminationResult();
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //
      this.get('demo', { demoCode: this.demoCode })
        .then((success) => {
          //
          let data = success.data;

          this.apo = data.apo;
          this.demo = data;
          //

          // デモの残債を更新できるようにする
          this.residualBonds = data.residualBonds;
          this.underwritingBonds = data.underwritingBonds;
          this.complimentaryBonds = data.complimentaryBonds;
          this.creator.residualBonds = data.residualBonds;
          this.creator.underwritingBonds = data.underwritingBonds;
          this.creator.complimentaryBonds = data.complimentaryBonds;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      // allNG状態か？
      let allNG =
        this.creator.examinationResult.results.filter((a) => a.report).filter((a) => a.state == 1 << 3).length ==
        this.creator.examinationResult.results.length;
      if (allNG && confirm('全件NGで登録してもよろしいですか？')) {
        this.creator.state = 1 << 4;
      }
      //
      let request = this.creator;
      request.demoCode = this.demoCode;
      request.examinationDate = this.convertDate(this.creator.examinationDate);
      request.examinationResultDate = this.convertDate(this.creator.examinationResultDate);
      //

      // nullable
      request.rate = this.convertNumber(request.rate);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      this.post('leasePayment', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', success.data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },

    initExaminationResult() {
      console.log('initExaminationResult', this.examinationResult);
      this.examinationResult.results = [
        LeasePaymentCompanyTypes.Orix,
        LeasePaymentCompanyTypes.CreditSaison,
        LeasePaymentCompanyTypes.Smbc,
        LeasePaymentCompanyTypes.Others,
      ].map((a) => ({ leaseCompanyType: a, state: null, examinationDate: null, examinationResultDate: null, report: false, memo: null }));

      console.log('initExaminationResult2', this.examinationResult);
      // view側の一覧を使用するためcreatorも初期化
      this.creator.examinationResult.results = [
        LeasePaymentCompanyTypes.Orix,
        LeasePaymentCompanyTypes.CreditSaison,
        LeasePaymentCompanyTypes.Smbc,
        LeasePaymentCompanyTypes.Others,
      ].map((a) => ({
        leaseCompanyType: a,
        state: 1, // default:リース会社提出待ち
        examinationDate: null,
        examinationResultDate: null,
        report: false,
        memo: null,
      }));

      console.log('initExaminationResult3', this.creator.examinationResult);
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>