<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      請求書発行
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.invoiceDate)">{{
                      isEmptyValue(creator.invoiceDate) ? '*請求書発行日を登録してください' : '*請求書発行日'
                    }}</XCheckLabel>
                    <span
                      v-if="creator.invoiceDate != null"
                      class="ml-4 success--text"
                      v-text="$options.filters.date(creator.invoiceDate)"
                    ></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="*請求書発行日">
                          <validation-provider v-slot="{ errors }" name="請求書発行日" rules="required">
                            <DateTime label="請求書発行日" v-model="invoiceDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ invoiceDate | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            invoiceDate = null;
                            creator.invoiceDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.invoiceDate = invoiceDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.amount != null">
                      <span>{{ creator.amount == null ? '*金額を登録してください' : '金額' }}</span>
                    </XCheckLabel>

                    <span
                      v-if="creator.amount != null"
                      class="ml-4 success--text"
                      v-text="`${$options.filters.priceJP(creator.amount)}`"
                    ></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="項目名" rules="">
                          <v-text-field
                            v-model="description"
                            label="項目名"
                            hint="「システム契約現金支払い」などの伝票名を記入します"
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                            clearable
                            required
                            dense
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="金額" rules="required">
                          <v-text-field
                            type="number"
                            v-model.number="amount"
                            label="*金額"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="残債" rules="">
                          <v-text-field
                            class="ml-4"
                            type="number"
                            v-model.number="residualBonds"
                            label="残債"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="他社買取" rules="">
                          <v-text-field
                            class="ml-4"
                            type="number"
                            v-model.number="underwritingBonds"
                            label="他社買取"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="サービス品" rules="">
                          <v-text-field
                            class="ml-4"
                            type="number"
                            v-model.number="complimentaryBonds"
                            label="サービス品"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            description = null;
                            amount = null;
                            creator.description = null;
                            creator.amount = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.description = description;
                            creator.amount = amount;
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span>備考: </span>
                    <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ demoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from '@/components/demos/Result';
export default {
  components: {
    ApoResult,
    DemoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    canCommit() {
      if (this.isEmptyValue(this.creator.invoiceDate)) return false;
      if (this.isEmptyValue(this.creator.amount)) return false;
      return true;
    },
  },
  data: () => ({
    //
    initializing: true,

    expansions: true,

    apo: null,
    demo: null,
    //
    description: null,
    invoiceDate: null,
    amount: null,
    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,
    memo: null,

    creator: {
      loading: false,

      description: null,
      invoiceDate: null,
      amount: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,
      memo: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.apo = null;
      this.demo = null;
      this.memo = null;
      //
      //
      this.description = null;
      this.invoiceDate = null;
      this.amount = null;
      this.residualBonds = null;
      this.underwritingBonds = null;
      this.complimentaryBonds = null;

      this.creator = {
        description: null,
        invoiceDate: null,
        amount: null,
        residualBonds: null,
        underwritingBonds: null,
        complimentaryBonds: null,
        memo: null,
      };
      //
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //
      this.get('demo', { demoCode: this.demoCode })
        .then((success) => {
          //
          let data = success.data;

          this.apo = data.apo;
          this.demo = data;
          //

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      //
      let request = this.creator;
      request.demoCode = this.demoCode;
      request.invoiceDate = this.convertDate(this.creator.invoiceDate);

      // nullable
      request.amount = this.convertNumber(request.amount);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      //
      this.post('demo/sale', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', success.data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>