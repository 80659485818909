export const toBlob = (base64, contentType) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], {
    type: contentType,
  });
  return blob;
};

export const download = (base64, contentType, fileName) => {
  const blob = toBlob(base64, contentType);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = fileName;
  a.href = url;
  a.click();
  a.remove();
};
