<template>
  <span>
    <template v-if="!hasFlag(demo.contractDocumentType, type)">
      <v-icon v-text="'mdi-minus'" small />
    </template>
    <template v-else>
      <template v-if="!hasFlag(demo.unContractDocumentType, type)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="getContractDocument(demo, type).ignoreSubmitDate ? 'blue' : 'success'"
              v-text="'mdi-check-circle-outline'"
              v-bind="attrs"
              v-on="on"
              small
            />
          </template>
          <span v-if="getContractDocument(demo, type).ignoreSubmitDate">不要</span>
          <span v-else>{{ getContractDocument(demo, type).submitDate | date }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip color="warning" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning" v-text="'mdi-alert'" v-bind="attrs" v-on="on" small />
          </template>
          <span>未登録</span>
        </v-tooltip>
      </template>
    </template>
  </span>
</template>


<script>
export default {
  components: {},
  props: {
    demo: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  computed: {},

  data: () => ({}),

  watch: {},

  methods: {
    onLoaded() {},

    getContractDocument(demo, val) {
      let contractDocuments = demo.meta.contractDocuments;
      let doc = contractDocuments.find((a) => a.contractDocumentType == val);
      // 万が一nullの場合はオブジェクトで返す
      if (doc == null) {
        doc = {
          submitDate: null,
          ignoreSubmitDate: false,
        };
      }
      return doc;
    },
  },
  created() {},
};
</script>