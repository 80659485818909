var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title)+" "),_c('span',{staticClass:"text-caption"},[_vm._v("件数 = "+_vm._s(_vm.items.length)+"件")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.demoDate",fn:function(ref){
var item = ref.item;
return [(item.demo && item.demo.date)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.demo.date)))]):(item.date)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.date)))]):_vm._e()]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [(item.branch)?_c('span',[_vm._v(_vm._s(item.branch.name))]):(item.demo && item.demo.branch)?_c('span',[_vm._v(_vm._s(item.demo.branch.name))]):_vm._e()]}},{key:"item.contractType",fn:function(ref){
var item = ref.item;
return [(item.demo)?_c('span',[_vm._v(_vm._s(_vm._f("demoContractType")(item.demo.contractType)))]):_c('span',[_vm._v(_vm._s(_vm._f("demoContractType")(item.contractType)))])]}},{key:"item.facility",fn:function(ref){
var item = ref.item;
return [(item.facility)?_c('span',[_vm._v(_vm._s(item.facility.name))]):(item.demo && item.demo.facility)?_c('span',[_vm._v(_vm._s(item.demo.facility.name))]):_vm._e()]}},{key:"item.systemTypes",fn:function(ref){
var item = ref.item;
return [(Array.isArray(item.systemTypes))?_c('span',[_vm._v(_vm._s(_vm._f("implode")(item.systemTypes.map(function (a) { return _vm.$options.filters.demoSystemType(a.value); }))))]):(item.demo && Array.isArray(item.demo.systemTypes))?_c('span',[_vm._v(_vm._s(_vm._f("implode")(item.demo.systemTypes.map(function (a) { return _vm.$options.filters.demoSystemType(a.value); }))))]):_vm._e()]}},{key:"item.apointer",fn:function(ref){
var item = ref.item;
return [(item.apointer)?_c('span',[_vm._v(_vm._s(item.apointer.name))]):(item.apo && item.apo.apointer)?_c('span',[_vm._v(_vm._s(item.apo.apointer.name))]):(item.demo && item.demo.apointer)?_c('span',[_vm._v(_vm._s(item.demo.apointer.name))]):(item.demo && item.demo.apo && item.demo.apo.apointer)?_c('span',[_vm._v(_vm._s(item.demo.apo.apointer.name))]):_vm._e()]}},{key:"item.demointer",fn:function(ref){
var item = ref.item;
return [(item.demointer)?_c('span',[_vm._v(_vm._s(item.demointer.name))]):(item.demo && item.demo.demointer)?_c('span',[_vm._v(_vm._s(item.demo.demointer.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(_vm.type == 4)?[(item.hpCoverage)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.hpCoverage.date)))]):(item.demo.hpCoverage)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.demo.hpCoverage.scheduleDate)))]):_vm._e()]:_vm._e(),(_vm.type == 8)?[(item.demo.leasePayment)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.demo.leasePayment.examinationResultDate)))]):_vm._e()]:[(item.date)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.date)))]):_vm._e()]]}},{key:"item.leasePayment",fn:function(ref){
var item = ref.item;
return [(_vm.type == 1)?[(item.saleType)?_c('span',[_vm._v(_vm._s(_vm._f("demoSaleType")(item.saleType)))]):(item.demo && item.demo.saleType)?_c('span',[_vm._v(_vm._s(_vm._f("demoSaleType")(item.demo.saleType)))]):_vm._e()]:[(item.leasePayment)?_c('span',[_vm._v(_vm._s(_vm._f("leasePaymentCompanyTypeShort")(item.leasePayment.leaseCompanyType)))]):(item.demo && item.demo.leasePayment)?_c('span',[_vm._v(" "+_vm._s(_vm._f("leasePaymentCompanyTypeShort")(item.demo.leasePayment.leaseCompanyType))+" ")]):(item.demo)?_c('span',[_vm._v(_vm._s(_vm._f("demoSaleType")(item.demo.saleType)))]):_vm._e()]]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.resultAmount2)?_c('span',[_vm._v(_vm._s(_vm._f("priceJP")(item.resultAmount2)))]):(item.demo && item.demo.resultAmount2)?_c('span',[_vm._v(_vm._s(_vm._f("priceJP")(item.demo.resultAmount2)))]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-list-item',[_c('v-spacer'),_c('v-list-item-icon',[_c('span',{staticClass:"text-caption"},[_vm._v("売上合計:")]),_c('span',{staticClass:"ml-4 font-weight-bold"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.totalAmount)))])])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }