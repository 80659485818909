<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      提出書類編集
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
            </template>

            <table class="table table-bordered">
              <thead>
                <tr class="table-primary">
                  <th width="50">&nbsp;</th>
                  <th>書類名</th>
                  <th width="180">日付</th>
                  <th>不要</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!creator.system.disabled">
                  <th>
                    <v-icon
                      v-if="creator.system.submitDate != null || creator.system.ignoreSubmitDate"
                      :color="creator.system.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> あすなろシステム契約書 </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.system.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.system.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.system.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.system.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.system.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.system.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>

                <tr v-if="!creator.lease.disabled">
                  <th>
                    <v-icon
                      v-if="creator.lease.submitDate != null || creator.lease.ignoreSubmitDate"
                      :color="creator.lease.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> リース契約書 </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.lease.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.lease.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.lease.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.lease.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.lease.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.lease.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>

                <tr v-if="!creator.delivery.disabled">
                  <th>
                    <v-icon
                      v-if="creator.delivery.submitDate != null || creator.delivery.ignoreSubmitDate"
                      :color="creator.delivery.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> 納品確認書 </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.delivery.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.delivery.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.delivery.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.delivery.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.delivery.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.delivery.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>

                <tr v-if="!creator.support.disabled">
                  <th>
                    <v-icon
                      v-if="creator.support.submitDate != null || creator.support.ignoreSubmitDate"
                      :color="creator.support.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> サポート規約 </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.support.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.support.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.support.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.support.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.support.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.support.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>

                <tr v-if="!creator.sukesan.disabled">
                  <th>
                    <v-icon
                      v-if="creator.sukesan.submitDate != null || creator.sukesan.ignoreSubmitDate"
                      :color="creator.sukesan.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> すけさん契約申込書 </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.sukesan.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.sukesan.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.sukesan.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.sukesan.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.sukesan.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.sukesan.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>

                <tr v-if="!creator.wideNet.disabled">
                  <th>
                    <v-icon
                      v-if="creator.wideNet.submitDate != null || creator.wideNet.ignoreSubmitDate"
                      :color="creator.wideNet.ignoreSubmitDate ? 'blue' : 'success'"
                      v-text="'mdi-check-circle-outline'"
                    />
                  </th>
                  <th>
                    <v-sheet> ワイドネット </v-sheet>
                  </th>
                  <td>
                    <v-sheet height="25">
                      <template v-if="creator.wideNet.ignoreSubmitDate">
                        <span class="blue-grey--text text--darken-1 text-decoration-line-through">
                          {{ creator.wideNet.submitDate | date }}
                        </span>
                      </template>
                      <template v-else>
                        <validation-provider v-slot="{ errors }" name="業務回収日" rules="">
                          <DateTime label="業務回収日" v-model="creator.wideNet.submitDate" :error-messages="errors" hideTime>
                            <template v-slot="{ click }"
                              ><v-btn class="mt-n2" text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                >{{ creator.wideNet.submitDate | date }}
                              </v-btn>
                              <v-btn class="mt-n2" icon @click="creator.wideNet.submitDate = null" small>
                                <v-icon v-text="'mdi-close'" />
                              </v-btn>
                            </template>
                          </DateTime>
                        </validation-provider>
                      </template>
                    </v-sheet>
                  </td>
                  <td>
                    <v-sheet height="25">
                      <validation-provider v-slot="{ errors }" name="不要" rules="">
                        <v-checkbox class="ma-0 pa-0" v-model="creator.wideNet.ignoreSubmitDate" :error-messages="errors" dense />
                      </validation-provider>
                    </v-sheet>
                  </td>
                </tr>
              </tbody>
            </table>

            <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
              <v-textarea
                v-model="creator.memo"
                label="備考"
                rows="3"
                counter
                :maxlength="1000"
                :error-messages="errors"
                clearable
                outlined
              />
            </validation-provider>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ demoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from '@/components/demos/Result';

export default {
  components: {
    ApoResult,
    DemoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.creator.system.submitDate = null;
          this.creator.system.ignoreSubmitDate = false;
          this.creator.lease.submitDate = null;
          this.creator.lease.ignoreSubmitDate = false;
          this.creator.delivery.submitDate = null;
          this.creator.delivery.ignoreSubmitDate = false;
          this.creator.support.submitDate = null;
          this.creator.support.ignoreSubmitDate = false;
          this.creator.sukesan.submitDate = null;
          this.creator.sukesan.ignoreSubmitDate = false;
          this.creator.wideNet.submitDate = null;
          this.creator.wideNet.ignoreSubmitDate = false;
          this.creator.memo = null;

          this.$nextTick(() => this.observer.reset());
        },
      };
    },

    canCommit() {
      return true;
    },
  },
  data: () => ({
    //
    initializing: true,

    facilities: [],
    apo: null,
    demo: null,

    contractDocumentType: 0,

    creator: {
      loading: false,

      system: {
        type: 1,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },
      lease: {
        type: 1 << 1,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },
      delivery: {
        type: 1 << 2,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },
      support: {
        type: 1 << 3,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },
      sukesan: {
        type: 1 << 4,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },
      wideNet: {
        type: 1 << 5,
        submitDate: null,
        ignoreSubmitDate: false,
        disabled: false,
      },

      memo: null,
    },
  }),
  methods: {
    init() {
      this.form.reset();
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //

      //
      this.get('demo', { demoCode: this.demoCode })
        .then((success) => {
          //
          let data = success.data;

          this.apo = data.apo;
          this.demo = data;

          this.contractDocumentType = data.contractDocumentType;

          let contractDocuments = data.meta.contractDocuments;
          let system = contractDocuments.find((a) => a.contractDocumentType == 1);

          if (system != null) {
            this.creator.system.submitDate = system.submitDate;
            this.creator.system.ignoreSubmitDate = system.ignoreSubmitDate;
          }
          // あすなろシステム契約書
          this.creator.system.disabled = !this.hasFlag(this.contractDocumentType, 1);

          //
          let lease = contractDocuments.find((a) => a.contractDocumentType == 1 << 1);
          if (lease != null) {
            this.creator.lease.submitDate = lease.submitDate;
            this.creator.lease.ignoreSubmitDate = lease.ignoreSubmitDate;
          }
          // リース契約書
          this.creator.lease.disabled = !this.hasFlag(this.contractDocumentType, 1 << 1);
          //
          let delivery = contractDocuments.find((a) => a.contractDocumentType == 1 << 2);
          if (delivery != null) {
            this.creator.delivery.submitDate = delivery.submitDate;
            this.creator.delivery.ignoreSubmitDate = delivery.ignoreSubmitDate;
          }

          // 納品確認書
          this.creator.system.delivery = !this.hasFlag(this.contractDocumentType, 1 << 2);
          //
          let support = contractDocuments.find((a) => a.contractDocumentType == 1 << 3);
          if (support != null) {
            this.creator.support.submitDate = support.submitDate;
            this.creator.support.ignoreSubmitDate = support.ignoreSubmitDate;
          }

          // サポート規約
          this.creator.system.support = !this.hasFlag(this.contractDocumentType, 1 << 3);
          //
          let sukesan = contractDocuments.find((a) => a.contractDocumentType == 1 << 4);
          if (sukesan != null) {
            this.creator.sukesan.submitDate = sukesan.submitDate;
            this.creator.sukesan.ignoreSubmitDate = sukesan.ignoreSubmitDate;
          }

          // すけさん契約申込書
          this.creator.sukesan.disabled = !this.hasFlag(this.contractDocumentType, 1 << 4);
          //
          let wideNet = contractDocuments.find((a) => a.contractDocumentType == 1 << 5);
          if (wideNet != null) {
            this.creator.wideNet.submitDate = wideNet.submitDate;
            this.creator.wideNet.ignoreSubmitDate = wideNet.ignoreSubmitDate;
          }

          // ワイドネット
          this.creator.wideNet.disabled = !this.hasFlag(this.contractDocumentType, 1 << 5);

          this.creator.memo = data.meta.contractDocumentMemo;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
      //
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.demoCode = this.demoCode;
      request.memo = this.creator.memo;

      // date変換が必要なためオブジェクト作成＋変換
      request.contractDocuments = [
        {
          type: this.creator.system.type,
          submitDate: this.convertDate(this.creator.system.submitDate),
          ignoreSubmitDate: this.creator.system.ignoreSubmitDate,
          disabled: this.creator.system.disabled,
        },
        {
          type: this.creator.lease.type,
          submitDate: this.convertDate(this.creator.lease.submitDate),
          ignoreSubmitDate: this.creator.lease.ignoreSubmitDate,
          disabled: this.creator.lease.disabled,
        },
        {
          type: this.creator.delivery.type,
          submitDate: this.convertDate(this.creator.delivery.submitDate),
          ignoreSubmitDate: this.creator.delivery.ignoreSubmitDate,
          disabled: this.creator.delivery.disabled,
        },
        {
          type: this.creator.support.type,
          submitDate: this.convertDate(this.creator.support.submitDate),
          ignoreSubmitDate: this.creator.support.ignoreSubmitDate,
          disabled: this.creator.support.disabled,
        },
        {
          type: this.creator.sukesan.type,
          submitDate: this.convertDate(this.creator.sukesan.submitDate),
          ignoreSubmitDate: this.creator.sukesan.ignoreSubmitDate,
          disabled: this.creator.sukesan.disabled,
        },
        {
          type: this.creator.wideNet.type,
          submitDate: this.convertDate(this.creator.wideNet.submitDate),
          ignoreSubmitDate: this.creator.wideNet.ignoreSubmitDate,
          disabled: this.creator.wideNet.disabled,
        },
      ];

      //
      this.put('demo/contractDocument', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.demoCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>
<style scoped>
td {
  text-align: center;
  vertical-align: center;
}

tr .disabled {
  background-color: 'blue-grey lighten-4';
}
</style>