var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" リース審査結果登録 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[(_vm.apo)?[_c('ApoResult',{attrs:{"apo":_vm.apo}}),_c('v-divider')]:_vm._e(),(_vm.demo)?[_c('DemoResult',{attrs:{"demo":_vm.demo}}),_c('v-divider')]:_vm._e(),_c('v-expansion-panels',{model:{value:(_vm.expansions),callback:function ($$v) {_vm.expansions=$$v},expression:"expansions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.leaseCompanyType)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.apoType) ? 'リース審査結果を登録してください' : 'リース審査結果')+" "),(_vm.creator.state != null)?_c('span',{staticClass:"success--text"},[_c('v-chip',{attrs:{"color":_vm.creator.state == _vm.LeasePaymentStates.OK ? 'success' : 'warning',"small":""}},[_vm._v(" "+_vm._s(_vm._f("leasePaymentState")(_vm.creator.state))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("leasePaymentCompanyType")(_vm.creator.leaseCompanyType)))]),_c('span',{staticClass:"ml-2"},[_vm._v("審査結果日: "+_vm._s(_vm._f("date")(_vm.creator.examinationResultDate)))])],1):_vm._e()]),_c('span',{staticClass:"ml-4 success--text",domProps:{"textContent":_vm._s(_vm.$options.filters.leasePaymentCompanyType(_vm.creator.leaseCompanyType))}})],1)]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"info","outlined":""}},[_vm._v("報告対象とする審査結果にチェックを付けてください ")]),_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((_vm.examinationResult.results),function(result,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_c('div',[_c('v-checkbox',{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();_vm.examinationResult.results
                                  .filter(function (a) { return a.leaseCompanyType != result.leaseCompanyType; })
                                  .forEach(function (a) { return (a.report = false); })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [([_vm.LeasePaymentStates.OK, _vm.LeasePaymentStates.NG].includes(result.state))?_c('v-chip',{attrs:{"color":result.state == _vm.LeasePaymentStates.OK ? 'success' : 'warning',"small":""}},[_vm._v(" "+_vm._s(_vm._f("leasePaymentState")(result.state))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("leasePaymentCompanyType")(result.leaseCompanyType))+" "),_c('span',{staticClass:"text-caption"},[(result.examinationResultDate != null)?_c('span',[_vm._v("結果日: "+_vm._s(_vm._f("date")(result.examinationResultDate)))]):_vm._e()])]},proxy:true}],null,true),model:{value:(result.report),callback:function ($$v) {_vm.$set(result, "report", $$v)},expression:"result.report"}})],1)]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"審査結果日"}},[_c('DateTime',{attrs:{"label":"審査結果日","hideTime":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(result.examinationResultDate))+" ")])]}}],null,true),model:{value:(result.examinationResultDate),callback:function ($$v) {_vm.$set(result, "examinationResultDate", $$v)},expression:"result.examinationResultDate"}})],1),_c('v-select',{attrs:{"items":[
                                  {
                                    text: _vm.$options.filters.leasePaymentState(_vm.LeasePaymentStates.Default),
                                    value: _vm.LeasePaymentStates.Default,
                                  },
                                  { text: _vm.$options.filters.leasePaymentState(_vm.LeasePaymentStates.OK), value: _vm.LeasePaymentStates.OK },
                                  { text: _vm.$options.filters.leasePaymentState(_vm.LeasePaymentStates.NG), value: _vm.LeasePaymentStates.NG } ],"label":"*審査結果","rules":[function (v) { return !!v || '審査結果は必須です'; }],"required":"","outlined":"","dense":""},model:{value:(result.state),callback:function ($$v) {_vm.$set(result, "state", $$v)},expression:"result.state"}}),_c('v-textarea',{attrs:{"outlined":"","label":"備考","counter":"","maxlength":1000,"clearable":""},model:{value:(result.memo),callback:function ($$v) {_vm.$set(result, "memo", $$v)},expression:"result.memo"}})],1)],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.leaseCompanyType = null;
                        _vm.creator.leaseCompanyType = null;
                        _vm.initExaminationResult();}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){// creator.leaseCompanyType = leaseCompanyType;

                        // NGではなく、報告対象としているもの
                        var result = _vm.examinationResult.results.find(function (a) { return a.report; });
                        if (result != null) {
                          _vm.creator.leaseCompanyType = result.leaseCompanyType;
                          _vm.creator.examinationResultDate = result.examinationResultDate;
                          _vm.creator.state = result.state;
                        } else {
                          _vm.creator.leaseCompanyType = null;
                          _vm.creator.examinationResultDate = null;
                          _vm.creator.state = null;
                        }
                        //
                        _vm.creator.examinationResult = _vm.examinationResult;

                        _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.rate)}},[(_vm.creator.state == _vm.LeasePaymentStates.OK)?_c('span',[_vm._v("*")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.rate) ? '料率を登録してください' : '料率'))])]),(_vm.creator.rate != null)?_c('span',{staticClass:"ml-4 success--text",domProps:{"textContent":_vm._s(("" + (_vm.creator.rate)))}}):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"料率","rules":_vm.creator.state == _vm.LeasePaymentStates.OK ? 'required|max:100' : 'max:100'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":[1.83, 1.82],"label":"料率","hint":("現在の売上見込み金額は " + (_vm.$options.filters.priceJP(_vm.demo.prospectAmount)) + " です"),"persistent-hint":"","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=_vm._n($$v)},expression:"rate"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.rate = null;
                          _vm.creator.rate = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.rate = _vm.rate;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('XCheckLabel',{attrs:{"value":_vm.creator.residualBonds != null}},[_vm._v(_vm._s(_vm.creator.residualBonds == null ? '残債、他社買取、サービス品を登録してください' : '残債、他社買取、サービス品'))]),_c('div',[(_vm.creator.residualBonds != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v(" 残債:"+_vm._s(_vm._f("priceJP")(_vm.creator.residualBonds)))]):_vm._e(),(_vm.creator.underwritingBonds != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v(" 他社買取:"+_vm._s(_vm._f("priceJP")(_vm.creator.underwritingBonds)))]):_vm._e(),(_vm.creator.complimentaryBonds != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v(" サービス品:"+_vm._s(_vm._f("priceJP")(_vm.creator.complimentaryBonds)))]):_vm._e()])],1),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"残債","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-2",attrs:{"type":"number","label":"残債","counter":"","maxlength":10,"error-messages":errors,"clearable":"","dense":"","suffix":"円"},model:{value:(_vm.residualBonds),callback:function ($$v) {_vm.residualBonds=_vm._n($$v)},expression:"residualBonds"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"他社買取","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-2",attrs:{"type":"number","label":"他社買取","counter":"","maxlength":10,"error-messages":errors,"clearable":"","dense":"","suffix":"円"},model:{value:(_vm.underwritingBonds),callback:function ($$v) {_vm.underwritingBonds=_vm._n($$v)},expression:"underwritingBonds"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"サービス品","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-2",attrs:{"type":"number","label":"サービス品","counter":"","maxlength":10,"error-messages":errors,"clearable":"","dense":"","suffix":"円"},model:{value:(_vm.complimentaryBonds),callback:function ($$v) {_vm.complimentaryBonds=_vm._n($$v)},expression:"complimentaryBonds"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.residualBonds = null;
                          _vm.underwritingBonds = null;
                          _vm.complimentaryBonds = null;
                          _vm.creator.residualBonds = null;
                          _vm.creator.underwritingBonds = null;
                          _vm.creator.complimentaryBonds = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.residualBonds = _vm.residualBonds;
                          _vm.creator.underwritingBonds = _vm.underwritingBonds;
                          _vm.creator.complimentaryBonds = _vm.complimentaryBonds;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('span',[_vm._v("備考: ")]),_c('span',{staticClass:"d-inline-block text-truncate ml-2",staticStyle:{"max-width":"120px"}},[_vm._v(_vm._s(_vm.creator.memo))])])]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"タイトル","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.memo = null;
                          _vm.creator.memo = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.memo = _vm.memo;
                          _vm.expansions = null;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1)],1)],2):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }