<template>
  <div>
    <v-data-table class="elevation-1" dense :headers="headers" :items="items" :items-per-page="-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            >{{ title }} <span class="text-caption">件数 = {{ items.length }}件</span></v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:item.demoDate="{ item }">
        <span v-if="item.demo && item.demo.date">{{ item.demo.date | date }}</span>
        <span v-else-if="item.date">{{ item.date | date }}</span>
      </template>

      <template v-slot:item.branch="{ item }">
        <span v-if="item.branch">{{ item.branch.name }}</span>
        <span v-else-if="item.demo && item.demo.branch">{{ item.demo.branch.name }}</span>
      </template>

      <template v-slot:item.contractType="{ item }">
        <span v-if="item.demo">{{ item.demo.contractType | demoContractType }}</span>
        <span v-else>{{ item.contractType | demoContractType }}</span>
      </template>

      <template v-slot:item.facility="{ item }">
        <span v-if="item.facility">{{ item.facility.name }}</span>
        <span v-else-if="item.demo && item.demo.facility">{{ item.demo.facility.name }}</span>
      </template>

      <template v-slot:item.systemTypes="{ item }">
        <span v-if="Array.isArray(item.systemTypes)">{{
          item.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode
        }}</span>
        <span v-else-if="item.demo && Array.isArray(item.demo.systemTypes)">{{
          item.demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode
        }}</span>
      </template>

      <template v-slot:item.apointer="{ item }">
        <span v-if="item.apointer">{{ item.apointer.name }}</span>
        <span v-else-if="item.apo && item.apo.apointer">{{ item.apo.apointer.name }}</span>
        <span v-else-if="item.demo && item.demo.apointer">{{ item.demo.apointer.name }}</span>
        <span v-else-if="item.demo && item.demo.apo && item.demo.apo.apointer">{{ item.demo.apo.apointer.name }}</span>
      </template>

      <template v-slot:item.demointer="{ item }">
        <span v-if="item.demointer">{{ item.demointer.name }}</span>
        <span v-else-if="item.demo && item.demo.demointer">{{ item.demo.demointer.name }}</span>
      </template>

      <template v-slot:item.date="{ item }">
        <template v-if="type == 4">
          <span v-if="item.hpCoverage">{{ item.hpCoverage.date | date }}</span>
          <span v-else-if="item.demo.hpCoverage">{{ item.demo.hpCoverage.scheduleDate | date }}</span>
        </template>
        <template v-if="type == 8">
          <span v-if="item.demo.leasePayment">{{ item.demo.leasePayment.examinationResultDate | date }}</span>
        </template>

        <template v-else>
          <span v-if="item.date">{{ item.date | date }}</span>
        </template>
      </template>

      <template v-slot:item.leasePayment="{ item }">
        <template v-if="type == 1">
          <span v-if="item.saleType">{{ item.saleType | demoSaleType }}</span>
          <span v-else-if="item.demo && item.demo.saleType">{{ item.demo.saleType | demoSaleType }}</span>
        </template>
        <template v-else>
          <span v-if="item.leasePayment">{{ item.leasePayment.leaseCompanyType | leasePaymentCompanyTypeShort }}</span>
          <span v-else-if="item.demo && item.demo.leasePayment">
            {{ item.demo.leasePayment.leaseCompanyType | leasePaymentCompanyTypeShort }}
          </span>
          <span v-else-if="item.demo">{{ item.demo.saleType | demoSaleType }}</span>
        </template>
      </template>

      <template v-slot:item.amount="{ item }">
        <span v-if="item.resultAmount2">{{ item.resultAmount2 | priceJP }}</span>
        <span v-else-if="item.demo && item.demo.resultAmount2">{{ item.demo.resultAmount2 | priceJP }}</span>
      </template>

      <template v-slot:footer>
        <v-container fluid>
          <v-list-item>
            <v-spacer />
            <v-list-item-icon>
              <span class="text-caption">売上合計:</span>
              <span class="ml-4 font-weight-bold">{{ totalAmount | priceJP }}</span>
            </v-list-item-icon>
          </v-list-item>
        </v-container>
      </template>
    </v-data-table>
  </div>
</template>


<script>
export default {
  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },

    totalAmount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    headers() {
      // 1: 審査中,1<<1: 未導入物件, 1<<2: 導入決定物件 ,1<<3 :リース開始確認待ち
      let dateTile = '予定日';
      if (this.type == 1) dateTile = '導入予定日';
      if (this.type == 1 << 1) dateTile = '導入予定日';
      if (this.type == 1 << 2) dateTile = '導入予定日';
      if (this.type == 1 << 3) dateTile = '導入日';

      let headers = [
        {
          text: '受注日',
          align: 'start',
          sortable: false,
          value: 'demoDate',
        },
        { text: '営業所', value: 'branch' },
        { text: '受注区分', value: 'contractType' },
        { text: '屋号', value: 'facility' },
        { text: '契約内容', value: 'systemTypes' },
        { text: 'アポ担当', value: 'apointer' },
        { text: 'デモ担当', value: 'demointer' },
        { text: dateTile, value: 'date' },
        { text: 'リース', value: 'leasePayment' },
        { text: '売上税抜額', value: 'amount' },
      ];

      return headers;
    },
  },

  data: () => ({}),

  watch: {},

  methods: {
    onLoaded() {},
  },
  created() {
    this.onLoaded();
  },
};
</script>