<template>
  <v-card flat>
    <v-card-title>
      <v-spacer />
      <v-btn color="primary" outlined @click="onCommit">検索</v-btn>
    </v-card-title>
    <v-card-text>
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.FacilityName)) != 0" dense>
        <v-col cols="3"> 宿名 </v-col>
        <v-col cols="9">
          <v-text-field v-model="editor.facilityName" clearable dense outlined></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row v-if="(disps & (FilterDisps.All | FilterDisps.ApoBranch)) != 0" dense>
        <v-col cols="3"> 支店(アポ) </v-col>
        <v-col cols="9">
          <v-select
            v-model="editor.apoBranch"
            :items="dataContext.shitens"
            label="*支店"
            item-text="name"
            item-value="id"
            return-object
            required
            dense
            clearable
          />
        </v-col>
      </v-row>
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.DemoBranch)) != 0" dense>
        <v-col cols="3"> 支店(デモ) </v-col>
        <v-col cols="9">
          <v-select
            v-model="editor.demoBranch"
            :items="dataContext.shitens"
            label="*支店"
            item-text="name"
            item-value="id"
            return-object
            required
            dense
            clearable
          />
        </v-col>
      </v-row> -->
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.ApoDates)) != 0" dense>
        <v-col cols="3"> アポ日 </v-col>
        <v-col cols="9">
          <DateTime v-model="editor.apoDates" label="sample" hideTime solo>
            <template v-slot:activator="{ on, attrs }">
              <a text class="text--primary" dark v-bind="attrs" v-on="on">
                {{ editor.apoDates[0] | date }} - {{ editor.apoDates[1] | date }}
              </a>
            </template>
          </DateTime>
          <v-btn text @click="editor.apoDates = [null, null]" small> <v-icon v-text="'mdi-close'" left />クリア</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.DemoDates)) != 0" dense>
        <v-col cols="3"> デモ日 </v-col>
        <v-col cols="9">
          <DateTime v-model="editor.demoDates" label="sample" hideTime solo>
            <template v-slot:activator="{ on, attrs }">
              <a text class="text--primary" dark v-bind="attrs" v-on="on">
                {{ editor.demoDates[0] | date }} - {{ editor.demoDates[1] | date }}
              </a>
            </template>
          </DateTime>
          <v-btn text @click="editor.demoDates = [null, null]" small> <v-icon v-text="'mdi-close'" left />クリア</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.HpCoverageScheduleDates)) != 0" dense>
        <v-col cols="3"> 導入取材予定日 </v-col>
        <v-col cols="9">
          <DateTime v-model="editor.hpCoverageScheduleDates" label="sample" hideTime solo>
            <template v-slot:activator="{ on, attrs }">
              <a text class="text--primary" dark v-bind="attrs" v-on="on">
                {{ editor.hpCoverageScheduleDates[0] | date }} - {{ editor.hpCoverageScheduleDates[1] | date }}
              </a>
            </template>
          </DateTime>
          <v-btn text @click="editor.hpCoverageScheduleDates = [null, null]" small><v-icon v-text="'mdi-close'" left />クリア</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="(disps & (FilterDisps.All | FilterDisps.HpCoverageDates)) != 0" dense>
        <v-col cols="3"> 導入取材完了日 </v-col>
        <v-col cols="9">
          <DateTime v-model="editor.hpCoverageDates" label="sample" hideTime solo>
            <template v-slot:activator="{ on, attrs }">
              <a text class="text--primary" dark v-bind="attrs" v-on="on">
                {{ editor.hpCoverageDates[0] | date }} - {{ editor.hpCoverageDates[1] | date }}
              </a>
            </template>
          </DateTime>
          <v-btn text @click="editor.hpCoverageDates = [null, null]" small><v-icon v-text="'mdi-close'" left />クリア</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template> 

<script>
import { FilterDisps } from '@/types';

export default {
  components: {},

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    disps: {
      type: Number,
      default: () => FilterDisps.All,
    },
  },
  computed: {
    FilterDisps() {
      return FilterDisps;
    },
  },
  data: () => ({
    //
    initializing: false,

    editor: {
      facilityName: null,
      apoBranch: null,
      demoBranch: null,
      apoDates: [null, null],
      demoDates: [null, null],
      hpCoverageScheduleDates: [null, null],
      hpCoverageDates: [null, null],
    },
  }),
  methods: {
    init() {
      //
    },
    //
    onLoaded() {
      // この画面の内容を返すだけなので、propからの値は不要
    },

    /**
     * フォーム決定イベント
     */
    onCommit() {
      this.$emit('commit', this.editor);
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>